import { useRef } from 'react';
import { useMediaQuery, useResizeObserver } from 'usehooks-ts';
import type { HalfAndHalfProps } from './half-and-half';
import cx from 'classnames';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { YouTubeVideo } from '@dx-ui/osc-youtube-video';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import {
  Video,
  VideoCaptions,
  VideoControls,
  VideoTranscript,
  useVideoPlayerClasses,
  useVideoPlayer,
  VideoPlayerMarkup,
} from '@dx-ui/osc-video-player';
import { useRect } from '@dx-ui/utilities-use-rect';

export type THalfAndHalfContentProps = HalfAndHalfProps & {
  isPositionOdd?: boolean;
};

const HalfAndHalfContent = ({
  imageUrl,
  captionData,
  headline,
  description,
  copy,
  imageAltText,
  id,
  ctaLink,
  hasParallax,
  isPositionOdd,
  media,
  speed = -10,
  cmsDocumentControl,
  brandComponentTheme,
}: THalfAndHalfContentProps) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const wrapperElement = useRef<React.ElementRef<'div'>>(null);
  const isVideo = Boolean(media?.video?.url || media?.multimedia?.videoUrl);
  const isYouTubeShort = media?.video?.source === 'youtube-short';
  const isPortrait = media?.multimedia?.orientation === 'portrait';
  const isLandscape = media?.multimedia?.orientation === 'landscape';
  const isVerticalVideo = isVideo && (isYouTubeShort || isPortrait);
  const videoUrl = media?.multimedia?.videoUrl || '';
  const playerProps = useVideoPlayer({
    ...media?.multimedia,
    videoUrl,
    wrapperElement,
  });
  const playerClassNames = useVideoPlayerClasses({ isLandscape });
  const hasActiveTranscript = Boolean(playerProps.videoTranscriptProps?.activeTranscript);
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const videoSize = useResizeObserver({ ref: playerProps.videoProps.videoElement });
  const height = videoSize?.height ?? 0;
  const transcriptStyle = isLarge && !isVerticalVideo ? { height: `${height}px` } : undefined;

  const Transcript = hasActiveTranscript ? (
    <VideoTranscript
      {...playerProps.videoTranscriptProps}
      style={transcriptStyle}
      brandComponentTheme={brandComponentTheme}
      className={cx('p-4 overflow-hidden lg:mb-6', {
        '[&>ol]:lg:max-h-full [&>ol]:lg:pb-16': !isVerticalVideo,
        '[&>ol]:lg:max-h-64': isVerticalVideo,
      })}
    />
  ) : null;

  const TextBodyWithLink =
    copy || ctaLink ? (
      <div>
        {copy ? (
          <BrandTextBody
            className="text-text brand-up:mb-12 brand-up:text-center brand-ou:text-primary !text-xl"
            brandComponentTheme={brandComponentTheme}
          >
            {copy}
          </BrandTextBody>
        ) : null}

        {ctaLink?.label && ctaLink?.url ? (
          <div className="pt-6">
            <BrandLink
              label={ctaLink.label}
              isNewWindow={ctaLink.isNewWindow}
              showNewWindowIcon={ctaLink.isNewWindow}
              url={ctaLink.url}
              brandComponentTheme={brandComponentTheme}
            />
          </div>
        ) : null}
      </div>
    ) : null;

  const HeadlineAndDescription =
    headline || description ? (
      <div className="lg:w-1/2">
        <div className="hidden lg:block">{Transcript}</div>
        {headline ? (
          <BrandTextHeader className="sm:!heading-3xl tracking-tight lg:mb-4">
            {headline}
          </BrandTextHeader>
        ) : null}

        {description ? (
          <BrandTextBody
            className="brand-ou:text-primary py-4 font-sans !text-xl lg:pt-0"
            brandComponentTheme={brandComponentTheme}
          >
            {description}
          </BrandTextBody>
        ) : null}

        <div className="hidden lg:block">{TextBodyWithLink}</div>
      </div>
    ) : null;

  return (
    <div className="container py-8" ref={wrapperElement}>
      {media?.multimedia?.markupSchemas ? (
        <VideoPlayerMarkup markupSchemas={media.multimedia.markupSchemas} />
      ) : null}

      <div
        className={cx('lg:flex justify-center w-full gap-12', {
          'flex-row-reverse': !isPositionOdd,
          'items-center': !hasActiveTranscript,
          'items-start': hasActiveTranscript,
          'max-w-sm mx-auto lg:max-w-full': isVerticalVideo,
        })}
        data-testid="halfNHalfContentWrapper"
      >
        {HeadlineAndDescription}
        <div
          className={cx({
            'lg:w-4/12 max-w-sm': isVerticalVideo,
            'lg:w-1/2': !isVerticalVideo,
          })}
        >
          {imageUrl && !isVideo ? (
            <div className="relative overflow-hidden py-2 lg:py-0" ref={ref}>
              <ParallaxBanner className="aspect-[3/2]" disabled={!hasParallax || isVideo}>
                <ParallaxBannerLayer
                  speed={speed}
                  expanded={false}
                  scale={[1.2, 1.2]}
                  disabled={!hasParallax || isVideo}
                >
                  <ResponsiveImage
                    aspectRatio="3:2"
                    id={id}
                    imageUrl={imageUrl}
                    altText={imageAltText ?? ''}
                    width={rect?.width ?? 0}
                    captionData={captionData}
                  />
                </ParallaxBannerLayer>
              </ParallaxBanner>
            </div>
          ) : null}

          {isVideo ? (
            <div className="relative py-2 lg:py-0">
              {media?.video?.url && media.video.title ? (
                <YouTubeVideo
                  {...media.video}
                  wrapperClassName="overflow-hidden"
                  id={media.video.title.trim()}
                  isVertical={isVerticalVideo}
                  isAutoPlay={false}
                />
              ) : media?.multimedia?.videoUrl ? (
                <>
                  <div
                    className={cx(playerClassNames.wrapperClassNames, 'mb-8', {
                      'sm:mb-0': !isVerticalVideo,
                      'lg:mb-0': isVerticalVideo,
                    })}
                  >
                    <Video
                      {...playerProps.videoProps}
                      captionData={media?.multimedia?.captionData}
                      isAutoPlay={media?.multimedia?.isAutoPlay}
                      posterImageUrl={media?.multimedia?.posterImageUrl}
                      videoId={media?.multimedia?.videoId}
                      videoUrl={videoUrl}
                    />
                    <VideoCaptions
                      {...playerProps.videoCaptionProps}
                      brandComponentTheme={brandComponentTheme}
                      className={playerClassNames.captionClassNames}
                    />
                    <VideoControls
                      {...playerProps.videoControlsProps}
                      brandComponentTheme={brandComponentTheme}
                      className={playerClassNames.controlsClassNames}
                    />
                  </div>
                  <div className="lg:hidden">{Transcript}</div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="lg:hidden">{TextBodyWithLink}</div>
      </div>
      {cmsDocumentControl}
    </div>
  );
};

export { HalfAndHalfContent };
export default HalfAndHalfContent;
