import type * as React from 'react';
import type { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { FallbackProps } from 'react-error-boundary';
import type { EnvironmentVariables } from '@dx-ui/cpm-mapping-shared';
import Icon, { type IconNames } from '@dx-ui/osc-icon';

type TNumber = {
  label: string;
  phone: string;
  dataE2e?: string;
};

export type TContactInfo = {
  dataE2e?: string;
  title: string;
  showIcon: boolean;
  numbers: Array<TNumber>;
  phoneText: string;
};

function isContactInfo(x: unknown): x is TContactInfo {
  if (typeof x !== 'object') {
    return false;
  }

  if (!x) {
    return false;
  }

  if ('title' in x && typeof x.title !== 'string') {
    return false;
  }

  if ('showIcon' in x && typeof x.showIcon !== 'boolean') {
    return false;
  }

  if ('numbers' in x && !Array.isArray(x.numbers)) {
    return false;
  }

  if ('phoneText' in x && typeof x.phoneText !== 'string') {
    return false;
  }

  return true;
}

export type THelpfulLinkItem = {
  linkURL: string;
  linkLabel: string;
  icon: (typeof IconNames)[number];
  index: number;
};

export type ErrorProps = {
  statusCode: '404' | '500';
  env: EnvironmentVariables;
};

const DEFAULT_LANGUAGE = 'en';

const ContactInfo: React.FC<TContactInfo & { env: EnvironmentVariables }> = ({
  dataE2e,
  title,
  showIcon,
  numbers,
  phoneText,
}) => {
  return (
    <div className="mb-4 flex flex-col justify-center lg:px-16 lg:pt-2">
      <div className="-ms-11 mb-2 flex">
        {showIcon && <Icon className="me-2" name="telephone" size="lg" variant="solid" />}
        <h3
          data-e2e={dataE2e}
          data-testid="contactInfoHeading"
          className="mb-1 inline-block align-bottom text-lg font-bold lg:text-xl"
        >
          {title}
        </h3>
      </div>
      {numbers?.map((number) => (
        <p key={number.label} className="mb-1 text-lg md:ms-6">
          {number.label} <span className="sr-only">{phoneText}</span>
          <a href={`tel:+${number.phone}`}>
            <span data-e2e={number.dataE2e} dir="ltr">
              +{number.phone}
            </span>
          </a>
        </p>
      ))}
    </div>
  );
};

const HelpfulLinkItem: React.FC<THelpfulLinkItem> = (props) => {
  const { linkURL, linkLabel, icon, index } = props;

  return (
    <>
      {index > 0 ? <div className="border-border mx-4 h-6 w-0 border-solid md:border-s" /> : null}
      <div className="flex items-center text-5xl md:mx-4">
        <a className="flex items-center text-lg underline" href={linkURL}>
          <span className="mr-3">
            <Icon name={icon} />
          </span>
          {linkLabel}
        </a>
      </div>
    </>
  );
};

export const Error: NextPage<ErrorProps> = ({ statusCode, env }: ErrorProps) => {
  const language = useRouter()?.query?.language || DEFAULT_LANGUAGE;

  const { t } = useTranslation('osc-marketing-error');
  const links = {
    accountUrl: `https://www.hilton.com/${language}/hilton-honors/guest/my-account/`,
    brandName: 'Hilton',
    brandUrl: `https://www.hilton.com/${language}/`,
    searchUrl: `https://www.hilton.com/${language}/search/find-hotels/`,
  };

  const errorText = t(statusCode, { returnObjects: true });
  const heading =
    'heading' in errorText && typeof errorText.heading === 'string' ? errorText.heading : '';
  const subHeading =
    'subHeading' in errorText && typeof errorText.subHeading === 'string'
      ? errorText.subHeading
      : '';

  const contactInfo = t('contactInfo', { returnObjects: true });
  const honors =
    'honors' in contactInfo && isContactInfo(contactInfo.honors) ? contactInfo.honors : null;
  const brand =
    'brand' in contactInfo && isContactInfo(contactInfo.brand) ? contactInfo.brand : null;

  const helpfulLinks = t('helpfulLinks', { ...links, returnObjects: true }) ?? [];

  return (
    <div className="m-4 flex h-screen flex-col items-start justify-start p-4 lg:items-center lg:justify-center">
      <h1
        data-e2e="pageTitle"
        className="text-primary mb-6 text-3xl font-black uppercase md:text-5xl"
      >
        {heading}
      </h1>
      <h2 className="mb-10 text-2xl font-bold md:mb-20" data-e2e="pageSubTitle">
        {subHeading}
      </h2>
      <nav className="mb-16 flex flex-col items-start justify-start md:flex-row">
        {Array.isArray(helpfulLinks) &&
          helpfulLinks.map((link, index) => {
            const remappedIcon = link.icon === 'account' ? 'user' : link.icon;
            return (
              <HelpfulLinkItem
                key={`${link.linkURL}${link.icon}`}
                linkURL={link.linkURL}
                linkLabel={link.linkLabel}
                icon={remappedIcon as (typeof IconNames)[number]}
                index={index}
              />
            );
          })}
      </nav>
      <div className="mb-6 flex flex-col items-start justify-start lg:flex-row">
        {brand ? <ContactInfo {...brand} env={env} /> : null}
        <div className="border-border mx-4 h-full w-0 border-solid md:border-s" />
        {honors ? <ContactInfo {...honors} env={env} /> : null}
      </div>
    </div>
  );
};

export const ErrorFallback: React.FC<
  Omit<FallbackProps, 'error'> & { error: Error & ErrorProps; env: EnvironmentVariables }
> = ({ error, env }) => {
  return <Error statusCode={error.statusCode} env={env} />;
};
