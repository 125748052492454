import type { OscDomLink, CpmNavigationList, CpmBrandNavigationList } from '@dx-ui/cpm-sdk';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { type TDrawerItem } from '@dx-ui/osc-header';
import { useAuth } from '@dx-ui/framework-auth-provider';
import type { EnvironmentVariables } from '@dx-ui/cpm-mapping-shared';
import {
  BrandComponentThemeInline,
  useBrandData,
  useEnvironmentVariables,
} from '@dx-ui/cpm-mapping-shared';
import { getBaseUrl } from '../../utils/constants';
import { useRouter } from 'next/router';
import { BrandsHeader } from '@dx-ui/osc-brands-header';

const replaceLang = (stringValue: string, lang: string) => stringValue?.replace('__LANG__', lang);

const getHonorsSignInUrl = (env: EnvironmentVariables, lang: string) =>
  replaceLang(env.DX_AUTH_UI ?? '', lang);
const getHonorsJoinUrl = (env: EnvironmentVariables, lang: string) =>
  replaceLang(env.HONORS_JOIN_URL ?? '', lang);
const getForgotInfoUrl = (env: EnvironmentVariables, lang: string) =>
  replaceLang(env.PARTNER_FORGET_INFO_LINK ?? '', lang);
const getGlobalPrivacyUrl = (env: EnvironmentVariables) => env.GLOBAL_PRIVACY_URL ?? '';
const getHonorsAccountUrl = (env: EnvironmentVariables, lang: string) =>
  getBaseUrl(env, `${lang}/hilton-honors/guest/my-account/`);

export default createCpmComponentDefinition(
  'Header',

  function mapData({ data }) {
    if (isBrandNavigationLinks(data?.items)) {
      const megaNavLinks = data.items
        ?.filter((item) => !!item.primaryNav)
        .map((navItem) => {
          const primary = navItem.primaryNav;
          const item: TDrawerItem = mapMegaNavLink(primary);
          const secondary = navItem.secondaryNavItems;

          if (secondary && secondary) {
            item.subMenu = secondary.map((navItem) => mapMegaNavLink(navItem));
          }

          return item;
        });

      return {
        megaNavLinks,
        type: 'BrandNavigationList',
      };
    }

    if (isNavigationLinks(data?.items)) {
      return {
        mainNavLinks: data?.items?.[0]?.links ?? [],
        type: 'NavigationList',
      };
    }

    return {
      type: 'Unmapped',
    };
  },

  function Header({
    navigationList: listData,
    componentParams,
    mappedPage: { hidePageHeader: hideHeader, brandCode, languageCode, seo, isHeaderH1 },
    metrics,
  }) {
    const env = useEnvironmentVariables();
    const brandData = useBrandData();
    const router = useRouter();
    const isPortfolioHomePage = router.asPath === '/';
    const showLanguageSelector = isPortfolioHomePage;
    const { guestInfo } = useAuth();

    const trackUserLoggedIn: Exclude<typeof metrics.trackUserLoggedIn, undefined> = (data) =>
      metrics.trackUserLoggedIn?.(data);

    const pageHeading = seo?.pageHeading ?? undefined;

    if (hideHeader) {
      return null;
    }

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        componentClassName="header"
      >
        <BrandsHeader
          programAccountId={guestInfo?.hhonors?.programAccountSummary?.[0]?.accountId}
          trackUserLoggedIn={trackUserLoggedIn}
          megaNavLinks={listData?.megaNavLinks}
          mainNavLinks={listData?.mainNavLinks}
          languageCode={languageCode}
          brandCode={brandCode}
          brandFormalName={brandData.formalName ?? undefined}
          brandUrl={brandData.url ?? undefined}
          showLanguageSelector={showLanguageSelector}
          honorsSignInUrl={getHonorsSignInUrl(env, languageCode)}
          honorsJoinUrl={getHonorsJoinUrl(env, languageCode)}
          forgotInfoUrl={getForgotInfoUrl(env, languageCode)}
          globalPrivacyUrl={getGlobalPrivacyUrl(env)}
          honorsAccountUrl={getHonorsAccountUrl(env, languageCode)}
          ohwBaseUrl={env.OHW_BASE_URL}
          heading={isHeaderH1 ? pageHeading : undefined}
        />
      </BrandComponentThemeInline>
    );
  }
);

function mapMegaNavLink(link?: OscDomLink) {
  return {
    label: link?.label || '',
    link: {
      url: link?.url || '',
      isNewWindow: !!link?.isNewWindow,
      showNewWindowIcon: !!link?.isNewWindow,
    },
  };
}

function isNavigationLinks(items: unknown): items is CpmNavigationList['items'] {
  return Array.isArray(items) && items.length > 0 && 'links' in items[0];
}

function isBrandNavigationLinks(items: unknown): items is CpmBrandNavigationList['items'] {
  return Array.isArray(items) && items.length > 0 && 'primaryNav' in items[0];
}
