import type {
  AwardData,
  BrandData,
  BrandNavigationListData,
  EditorialData,
  EventData,
  PolicyData,
  PlaceData,
  MessageData,
  PartnerData,
  StoryData,
  // FaqData,
  NavigationListData,
  BrComponent,
} from './adapters/types';
import type { OptionalUndefineds, Reify, UnionMapping, ValueOf } from './typeHelpers';
import {
  makeParamValidator,
  isPosition,
  isBoolean,
  isString,
  isAlignment,
  isOrientation,
  isImageDisplay,
  isTabDisplay,
  isEditorialDisplay,
  isTheme,
  isBackgroundIllustration,
  isIllustrationBlock,
} from './mappingEngine/parseComponentParams';
import { getPath } from './utils/unknown-object-helpers';

export const mappingSchema = {
  '3-6-9 Grid': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['threeSixNineGrid', 'threeSixNineExpansionPanel'],
    assets: ['1x1', '3x2'],
    componentParams: {
      animation: makeParamValidator(isBoolean, false),
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      display: makeParamValidator(isString, ''),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Story', 'Event', 'Place', 'Message'],
  },

  '4X Grid': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['fourXGrid', 'fourXExpansionPanel'],
    assets: ['3x4', '16x9'],
    componentParams: {
      animation: makeParamValidator(isBoolean, false),
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      display: makeParamValidator(isString, ''),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Story', 'Event', 'Place', 'Message'],
  },

  'Brand Showcase': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Brand'],
  },

  Accordion: {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: ['Award', 'Brand', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Brand Video': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Brand', 'Event', 'Partner', 'Place', 'Story'],
  },

  BrandAlerts: {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
  },

  Carousel: {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['brandCarousel'],
    // New aspect ratio image format - "asset" matches the name in the CMS
    assets: ['1x1', '3x2', '21x9'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      noMargin: makeParamValidator(isBoolean, false),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Story', 'Event', 'Brand', 'Place', 'Partner'],
  },

  Collage: {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['collage', 'threeSixNineGrid'],
    assets: ['1x1', '3x2'],
    componentParams: {
      animation: makeParamValidator(isBoolean, false),
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      imageDisplay: makeParamValidator(isAlignment, 'alternate' as const),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Story', 'Partner'],
  },

  Editorial: {
    mappingKind: 'DataDocument',
    isEditable: true,
    namedImages: ['collage', 'tabs'],
    assets: ['1x1', '3x2'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      display: makeParamValidator(isEditorialDisplay, 'default' as const),
      // Editorial component expects a default value
      theme: makeParamValidator(isTheme, '' as const),
      imageDisplay: makeParamValidator(isAlignment, 'none'),
    },
    cmsDocumentTypes: ['Editorial'],
  },

  Footer: {
    mappingKind: 'NavigationListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: ['NavigationList'],
  },

  'Film Strip': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['halfAndHalf', 'fourXExpansionPanel'],
    assets: ['3x2', '18x5', '16x9'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Full Width Image': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['fullWidth'],
    assets: ['3x2', '18x5'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Half & Half': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['halfAndHalf'],
    assets: ['3x2'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      imageDisplay: makeParamValidator(isString, ''),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Event', 'Message', 'Partner', 'Story', 'Place'],
  },

  Header: {
    mappingKind: 'NavigationListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: ['NavigationList', 'BrandNavigationList'],
  },

  'Honors Multicolumn': {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
  },

  'Honors Points Toolbar': {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
  },

  'Honors Rotating Tiles': {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
  },

  'Honors Tabbed Icons': {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
  },

  'Honors Tier Tracker': {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
  },

  'Illustration Block': {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
    componentParams: {
      illustration: makeParamValidator(isIllustrationBlock, 'none' as const),
    },
  },

  'Image Headliner': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['imageHeadliner'],
    assets: ['3x2'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Brand', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Masthead Image': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['fullWidth'],
    assets: ['3x2', '18x5', '16x9'],
    componentParams: {
      animation: makeParamValidator(isBoolean, false),
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      display: makeParamValidator(isImageDisplay, 'static'),
      horizontalLine: makeParamValidator(isBoolean, false),
      scrollingAnimation: makeParamValidator(isBoolean, false),
      textboxPosition: makeParamValidator(isString, ''),
      textAlign: makeParamValidator(isPosition, 'center' as const),
    },
    cmsDocumentTypes: ['Award', 'Brand', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Offset Grid': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['halfAndHalf', 'fourXExpansionPanel'],
    assets: ['2x1'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Brand', 'Editorial', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Patchwork Grid': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['collage', 'halfAndHalf', 'fourXExpansionPanel'],
    assets: ['1x1', '2x1'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Brand', 'Editorial', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Search Widget': {
    mappingKind: 'Unmapped',
    isEditable: false,
    namedImages: [],
    assets: [],
    cmsDocumentTypes: [],
    componentParams: {
      aaaRate: makeParamValidator(isBoolean, false),
      travelAgentRate: makeParamValidator(isBoolean, false),
      governmentOrMilitaryRate: makeParamValidator(isBoolean, false),
      seniorRate: makeParamValidator(isBoolean, false),
      redeemPts: makeParamValidator(isBoolean, false),
      aarpRate: makeParamValidator(isBoolean, false),

      deeplinkParameter1: makeParamValidator(isString, ''),
      deeplinkParameter2: makeParamValidator(isString, ''),
      deeplinkParameter3: makeParamValidator(isString, ''),
      deeplinkParameterValue1: makeParamValidator(isString, ''),
      deeplinkParameterValue2: makeParamValidator(isString, ''),
      deeplinkParameterValue3: makeParamValidator(isString, ''),
      displayOption: makeParamValidator(isString, ''),
    },
  },

  'Tabbed Section': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: ['tabs'],
    assets: ['3x2'],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      tabDisplay: makeParamValidator(isTabDisplay, 'text'),
      tabbedSectionDisplay: makeParamValidator(isOrientation, 'horizontal' as const),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Brand', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Text Component': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    componentParams: {
      animation: makeParamValidator(isBoolean, false),
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      borderTrim: makeParamValidator(isBoolean, false),
      textAlign: makeParamValidator(isString, ''),
    },
    cmsDocumentTypes: ['Policy', 'Story', 'Faq'],
  },

  'Text Highlight': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
      oneLinkNoTx1: makeParamValidator(isBoolean, false),
      oneLinkNoTx2: makeParamValidator(isBoolean, false),
      oneLinkNoTx3: makeParamValidator(isBoolean, false),
      oneLinkNoTx4: makeParamValidator(isBoolean, false),
      oneLinkNoTx5: makeParamValidator(isBoolean, false),
      oneLinkNoTx6: makeParamValidator(isBoolean, false),
      oneLinkNoTx7: makeParamValidator(isBoolean, false),
      oneLinkNoTx8: makeParamValidator(isBoolean, false),
      oneLinkNoTx9: makeParamValidator(isBoolean, false),
      oneLinkNoTx10: makeParamValidator(isBoolean, false),
      oneLinkNoTx11: makeParamValidator(isBoolean, false),
      oneLinkNoTx12: makeParamValidator(isBoolean, false),
      oneLinkNoTx13: makeParamValidator(isBoolean, false),
      oneLinkNoTx14: makeParamValidator(isBoolean, false),
      oneLinkNoTx15: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Event', 'Partner', 'Place', 'Story'],
  },

  'Video Poster': {
    mappingKind: 'ListDocument',
    isEditable: true,
    namedImages: [],
    assets: [],
    componentParams: {
      backgroundIllustration: makeParamValidator(isBackgroundIllustration, 'none' as const),
      backgroundParallax: makeParamValidator(isBoolean, false),
    },
    cmsDocumentTypes: ['Award', 'Brand', 'Event', 'Partner', 'Place', 'Story'],
  },
} as const;

export type MappingSchema = typeof mappingSchema;
export type MappedComponentName = keyof MappingSchema;
export type MappedComponent = ValueOf<MappingSchema>;

export function isMappedComponentName(x: string): x is MappedComponentName {
  return Object.keys(mappingSchema).includes(x);
}
export function getBrComponentName(componentBr: BrComponent): string {
  const name = getPath(componentBr, ['model', 'label']);
  return typeof name === 'string' ? name : '';
}

export type ImageVariant = MappedComponent['namedImages'][number];
export type MappingKind = MappedComponent['mappingKind'];

export type CmsDocumentType =
  | MappedComponent['cmsDocumentTypes'][number]
  | 'ListDocument'
  | 'NavigationList'
  | 'BrandNavigationList';

export type CmsNamedDataTypes = {
  Award: AwardData;
  Brand: BrandData;
  BrandNavigationList: BrandNavigationListData;
  Editorial: EditorialData;
  Event: EventData;
  Message: MessageData;
  Partner: PartnerData;
  Place: PlaceData;
  Policy: PolicyData;
  Story: StoryData;
  // Faq: FaqData; - TODO: work out why this breaks the types in all mapping files
  Faq: StoryData;
  NavigationList: NavigationListData;
};

export type CmsDataTypesUnion = CmsNamedDataTypes[MappedComponent['cmsDocumentTypes'][number]] & {
  ref: { $ref: string };
};
export type CmsDataTypesIntersection = Reify<OptionalUndefineds<UnionMapping<CmsDataTypesUnion>>>;
export type CmsUnknownComponentParams = Record<string, unknown>;
