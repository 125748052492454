import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';

export const Smb_MemberIdAndStatusDocument = {
  operationName: 'smb_MemberIdAndStatus',
  operationString: `query smb_MemberIdAndStatus($guestId: BigInt!, $language: String!) {
  programAccounts(guestId: $guestId, language: $language) {
    accountId
    accountName
    accountStatus
    memberId
    memberStatus
  }
}`,
  originalOpName: 'smb_MemberIdAndStatus',
};
export function useSmb_MemberIdAndStatusQuery<
  TData = Types.Smb_MemberIdAndStatusQuery,
  TError = unknown
>(
  variables: Types.Smb_MemberIdAndStatusQueryVariables,
  options?: Omit<UseQueryOptions<Types.Smb_MemberIdAndStatusQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Smb_MemberIdAndStatusQuery, TError, TData>({
    queryKey: [Smb_MemberIdAndStatusDocument, variables],
    ...options,
  });
}
export function serverSideSmb_MemberIdAndStatusQuery(
  queryClient: QueryClient,
  variables: Types.Smb_MemberIdAndStatusQueryVariables,
  options?: FetchQueryOptions<Types.Smb_MemberIdAndStatusQuery>
) {
  return queryClient.fetchQuery<Types.Smb_MemberIdAndStatusQuery>({
    queryKey: [Smb_MemberIdAndStatusDocument, variables],
    ...options,
  });
}

export const Smb_ProgramAccountMemberDocument = {
  operationName: 'smb_ProgramAccountMember',
  operationString: `query smb_ProgramAccountMember($guestId: BigInt!, $language: String!, $input: ProgramAccountsSmbMemberInput!) {
  programAccountMember(guestId: $guestId, language: $language, input: $input) {
    accountId
    guestId
    emailAddress: emailAddressMasked
    emailLastVerifiedDateFmt(language: $language, format: "short")
    memberId
    status
    role
  }
}`,
  originalOpName: 'smb_ProgramAccountMember',
};
export function useSmb_ProgramAccountMemberQuery<
  TData = Types.Smb_ProgramAccountMemberQuery,
  TError = unknown
>(
  variables: Types.Smb_ProgramAccountMemberQueryVariables,
  options?: Omit<UseQueryOptions<Types.Smb_ProgramAccountMemberQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Smb_ProgramAccountMemberQuery, TError, TData>({
    queryKey: [Smb_ProgramAccountMemberDocument, variables],
    ...options,
  });
}
export function serverSideSmb_ProgramAccountMemberQuery(
  queryClient: QueryClient,
  variables: Types.Smb_ProgramAccountMemberQueryVariables,
  options?: FetchQueryOptions<Types.Smb_ProgramAccountMemberQuery>
) {
  return queryClient.fetchQuery<Types.Smb_ProgramAccountMemberQuery>({
    queryKey: [Smb_ProgramAccountMemberDocument, variables],
    ...options,
  });
}
