import { useState, useRef, useCallback, useId } from 'react';
import { useRect } from '@dx-ui/utilities-use-rect';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import CardCarouselItem from './card-carousel-item';
import type { TCardCarouselItem } from './card-carousel-item';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import Icon from '@dx-ui/osc-icon';
import { getLanguageDirection } from '@dx-ui/utilities-get-language-direction';
import { HeadingLevel, HeadingLevelProvider } from '@dx-ui/osc-heading-level';

export type CardCarouselProps = {
  /** Item list from individual cards */
  items: TCardCarouselItem[];
  /** Main headline above Card Carousel */
  mainHeadline?: string;
  /** Main subheadline/short description above Card Carousel */
  mainShortDescription?: string;
  /** Handles arrow click */
  onArrowClick?: (index: number) => void;
  /** Determines brand color theme for component */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Determines if component is inside editorial component for size restrictions */
  isEditorialComponent?: boolean;
  /** Adds an additional border inside main border */
  isInsideBorder?: boolean;
};

/**
 * Card Carousel is can be a building block for CPM compatable components or a stand-alone OSC-Component. Creates a film strip style carousel that uses scroll-snap to change cards and adds additional content to your pages.
 * Does **not** continue to cycle through cards, only moves left or right. Click, scroll, or arrow through to see all the cards, it is almost as cool as a Pokemon collection.
 */
export const CardCarousel = ({
  items,
  mainHeadline,
  mainShortDescription,
  brandComponentTheme,
  isEditorialComponent,
  isInsideBorder,
}: CardCarouselProps) => {
  const [showPreviousArrow, setShowPreviousArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation('osc-card-carousel');
  const isRtl = getLanguageDirection(i18n.language) === 'rtl';
  const headerId = useId();

  const lessThanThreeItems = items.length < 3;

  const sideScroll = (element: HTMLDivElement | null, step: number) => {
    if (element) element.scrollLeft += step;
  };

  const handleNextButtonClick = () => {
    if (ref?.current?.clientWidth) {
      if (isRtl) sideScroll(ref.current, -ref?.current?.clientWidth * 0.6);
      else sideScroll(ref.current, ref?.current?.clientWidth * 0.6);
    }
  };

  const handlePreviousButtonClick = () => {
    if (ref?.current?.clientWidth) {
      if (isRtl) sideScroll(ref.current, ref?.current?.clientWidth * 0.6);
      else sideScroll(ref.current, -ref?.current?.clientWidth * 0.6);
    }
  };

  const onChange = useCallback((rect: DOMRect | undefined) => {
    const scrollLeft = ref?.current?.scrollLeft ?? 0;
    const scrollWidth = ref?.current?.scrollWidth ?? 0;
    const carouselWidth = rect?.width || 0;
    const maxScrollwidth = scrollWidth - carouselWidth;
    if (scrollLeft) {
      if (maxScrollwidth - scrollLeft < 50 || maxScrollwidth + scrollLeft < 50) {
        setShowNextArrow(false);
      } else {
        setShowNextArrow(true);
      }
      if (scrollLeft > 50 || scrollLeft < -50) {
        setShowPreviousArrow(true);
      } else if (scrollLeft < 50) {
        setShowPreviousArrow(false);
      }
    }
  }, []);

  const arrowButtonRect = useRect({ ref, onResize: onChange });
  const onScrollArrow = useCallback(
    () => arrowButtonRect && onChange(arrowButtonRect),
    [onChange, arrowButtonRect]
  );

  if (!items || !items?.length) {
    return null;
  }

  function setCardItemFocus(e: React.KeyboardEvent, activeElement: Element | null | undefined) {
    let itemToFocus: typeof activeElement = null;
    const useArrowKeys =
      activeElement
        ?.closest('.card-carousel_item')
        ?.closest('.card-carousel_item')
        ?.getAttribute('data-use-arrow-keys') === 'true';
    const cardCarouselItem = activeElement?.closest('.card-carousel_item');

    const goToNextItem = () => {
      itemToFocus = cardCarouselItem?.nextElementSibling;
      if (itemToFocus) {
        e.preventDefault();
        e.stopPropagation();
        if (itemToFocus.querySelector('a')) {
          const listElement = itemToFocus.querySelector('a');
          activeElement?.setAttribute('tabindex', '-1');
          listElement?.setAttribute('tabindex', '0');
          listElement?.focus();
        } else if (!itemToFocus.querySelector('a')) {
          const findNextItemToFocus = itemToFocus.nextElementSibling?.querySelector('a');
          activeElement?.setAttribute('tabindex', '-1');
          findNextItemToFocus?.setAttribute('tabindex', '0');
          (findNextItemToFocus as HTMLElement)?.focus();
        }
      }
    };

    const goToPreviousItem = () => {
      itemToFocus = cardCarouselItem?.previousElementSibling;
      if (itemToFocus) {
        e.preventDefault();
        e.stopPropagation();
        if (itemToFocus.querySelector('a')) {
          const listElement = itemToFocus.querySelector('a');
          activeElement?.setAttribute('tabindex', '-1');
          listElement?.setAttribute('tabindex', '0');
          listElement?.focus();
        } else if (!itemToFocus.querySelector('a')) {
          const findPreviousItemToFocus = itemToFocus.previousElementSibling?.querySelector('a');
          activeElement?.setAttribute('tabindex', '-1');
          findPreviousItemToFocus?.setAttribute('tabindex', '0');
          (findPreviousItemToFocus as HTMLElement)?.focus();
        }
      }
    };

    if (useArrowKeys) {
      if (e.key === 'ArrowRight') {
        if (isRtl) goToPreviousItem();
        else goToNextItem();
      } else if (e.key === 'ArrowLeft') {
        if (isRtl) goToNextItem();
        else goToPreviousItem();
      }
    }
  }

  const handleKeyPressDown = (e: React.KeyboardEvent) => {
    const activeElement = ref.current?.ownerDocument.activeElement;
    setCardItemFocus(e, activeElement);
  };

  const isLinkPresent = items.some((item) => {
    return item.link;
  });

  const findFirstLinkIndex = items.findIndex((item) => item.link);

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div
      className={cx({
        'brand-gu:bg-quarternary brand-ou:bg-secondary': !isDark && !isLight,
        'bg-bg-light brand-wa:!bg-transparent': isLight,
        'bg-bg-dark': isDark,
        'lg:ps-12': isEditorialComponent && lessThanThreeItems,
      })}
      data-testid="testCardCarouselTheme"
    >
      <div
        className={cx('flex flex-col px-[3.4rem]', {
          'sm:px-[.4rem] md:px-[3.4rem] lg:px-[.4rem]': lessThanThreeItems,
        })}
      >
        {isEditorialComponent ? (
          <HeadingLevel
            headingLevelFallback={3}
            className={cx(
              'text-text sm:text-lg lg:text-xl brand-wa:text-brand brand-wa:uppercase brand-wa:font-sans brand-wa:font-light',
              {
                '!text-text-inverse': isDark,
                'brand-ht:!text-text-inverse': isLight,
                'sr-only': !mainHeadline,
              }
            )}
            id={`carousel-heading-${headerId}`}
          >
            {mainHeadline || 'Content list'}
          </HeadingLevel>
        ) : null}

        {!isEditorialComponent ? (
          <HeadingLevel
            headingLevelFallback={2}
            className={cx(
              'font-headline text-brand text-4xl sm:text-5xl lg:text-5xl brand-wa:uppercase',
              {
                '!text-text-inverse': isDark,
                'brand-ht:!text-text-inverse': isLight,
                '!text-2xl sm:!text-2xl lg:!text-2xl text-bold': isEditorialComponent,
                'sr-only': !mainHeadline,
              }
            )}
            id={`carousel-heading-${headerId}`}
          >
            {mainHeadline || 'Content list'}
          </HeadingLevel>
        ) : null}

        {mainShortDescription ? (
          <BrandTextBody
            className={cx('text-xl sm:!text-xl lg:!text-2xl', {
              'text-text-inverse': isDark,
              'brand-ht:!text-text-inverse': isLight,
            })}
            brandComponentTheme={brandComponentTheme}
          >
            {mainShortDescription}
          </BrandTextBody>
        ) : null}
      </div>
      <div className="flex">
        <div
          className={cx('self-center', {
            invisible: !showPreviousArrow,
            'sm:hidden md:block lg:hidden': lessThanThreeItems,
          })}
        >
          <button
            type="button"
            className={cx('btn btn-primary-text text-text btn-lg rtl:-scale-x-100 h-11 w-11 me-1', {
              'border-bg': isDark,
              'brand-ht:border-bg': isLight,
              invisible: !showPreviousArrow,
            })}
            onClick={handlePreviousButtonClick}
            tabIndex={-1}
          >
            <Icon
              name="arrowhead-left"
              size="xl"
              className={cx('fill-text brand-ou:fill-primary', {
                'fill-text-inverse brand-ou:!fill-secondary': isDark,
                'brand-ht:fill-text-inverse': isLight,
              })}
            />
            <span className="sr-only">{t('previous')}</span>
          </button>
        </div>

        <div
          className={cx(
            'card-carousel snap-x snap-mandatory overflow-x-scroll scroll-smooth w-full',
            {
              'focus:outline outline-[3px] outline-primary outline-offset-2': !isLinkPresent,
              'outline-text-inverse': isDark,
              'sm:overflow-x-auto md:overflow-x-scroll lg:overflow-x-auto': lessThanThreeItems,
            }
          )}
          ref={ref}
          tabIndex={!isLinkPresent ? 0 : undefined}
          aria-labelledby={`carousel-heading-${headerId}`}
          onScroll={onScrollArrow}
        >
          <ul
            className={cx('grid-wrapper grid grid-flow-col auto-cols-[100%] sm:auto-cols-[50%]', {
              'md:auto-cols-[100%] lg:auto-cols-[50%]': isEditorialComponent,
              'xl:auto-cols-[25%]': !isEditorialComponent,
            })}
            data-testid="cardCarousel"
          >
            {items?.map((itemProps, index) => (
              <li
                className={cx(
                  'card-carousel_item bg-bg p-2 xl:p-5 my-2 snap-always snap-center sm:snap-start shrink-0',
                  {
                    'brand-gu:bg-quarternary brand-ou:bg-secondary': !isDark && !isLight,
                    'bg-bg-light brand-wa:bg-transparent': isLight,
                    'bg-bg-dark': isDark,
                    'xl:!p-2': isEditorialComponent,
                  }
                )}
                key={`carousel-image-${itemProps.id}`}
                data-testid={`image-carousel-wrap-${index + 1}`}
                onKeyDown={handleKeyPressDown}
                data-use-arrow-keys={true}
              >
                <div
                  className={cx(
                    'h-full border-brand border-solid border-[1px] p-2 xl:p-4 focus-within:outline outline-[3px] outline-primary outline-offset-2',
                    'brand-wa:border-primary',
                    {
                      'border-text-inverse outline-text-inverse brand-wa:border-text-inverse brand-ou:border-secondary':
                        isDark,
                      'brand-ht:border-bg outline-bg': isLight,
                      'brand-es-refresh:border-text brand-ey:border-primary-alt brand-gu:border-primary':
                        !isDark,
                      'xl:!p-2': isEditorialComponent,
                    }
                  )}
                >
                  <HeadingLevelProvider>
                    <CardCarouselItem
                      {...itemProps}
                      currentCard={index + 1}
                      totalCards={items?.length}
                      brandComponentTheme={brandComponentTheme}
                      isEditorialComponent={isEditorialComponent}
                      isInsideBorder={isInsideBorder}
                      tabIndex={index === findFirstLinkIndex ? 0 : -1}
                    />
                  </HeadingLevelProvider>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div
          className={cx('self-center', {
            invisible: !showNextArrow,
            'sm:hidden md:block lg:hidden': lessThanThreeItems,
          })}
        >
          <button
            type="button"
            className={cx('btn btn-primary-text btn-sm rtl:-scale-x-100 h-11 w-11', {
              'border-bg': isDark,
              'brand-ht:border-bg': isLight,
            })}
            onClick={handleNextButtonClick}
            tabIndex={-1}
          >
            <Icon
              name="arrowhead-right"
              size="xl"
              className={cx('fill-text brand-ou:fill-primary', {
                'fill-text-inverse brand-ou:!fill-secondary': isDark,
                'brand-ht:fill-text-inverse': isLight,
              })}
            />
            <span className="sr-only">{t('next')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardCarousel;
