/* eslint-disable no-console */
import * as React from 'react';
import { useIsomorphicLayoutEffect as useLayoutEffect } from '@reach/utils';
import observeRect from './oberserve-rect';

const __DEV__ = process.env.NODE_ENV === 'development';

////////////////////////////////////////////////////////////////////////////////

/**
 * Rect
 *
 * @param props
 */
const Rect: React.FC<RectProps> = ({ onChange, observe = true, children }) => {
  const ref = React.useRef<HTMLElement | null>(null);
  const rect = useRectBase(ref, { observe, onChange });
  return children({ ref, rect });
};

/**
 * @see Docs https://reach.tech/rect#rect-props
 */
type RectProps = UseRectOptions & {
  /**
   * A function that calls back to you with a `ref` to place on an element and
   * the `rect` measurements of the dom node.
   *
   * **Note**: On the first render `rect` will be `undefined` because we can't
   * measure a node that has not yet been rendered. Make sure your code accounts
   * for this.
   *
   * @see Docs https://reach.tech/rect#rect-onchange
   */
  children: (args: { rect: PRect | null; ref: React.RefObject<unknown> }) => JSX.Element;
};

Rect.displayName = 'Rect';

////////////////////////////////////////////////////////////////////////////////
/**
 * useRect
 *
 * @param nodeRef
 * @param observe
 * @param onChange
 */
function useRectBase<T extends Element = HTMLElement>(
  nodeRef: React.RefObject<T | undefined | null>,
  options?: UseRectOptions
): null | DOMRect {
  const [element, setElement] = React.useState(nodeRef.current);
  const initialRectIsSet = React.useRef(false);
  const initialRefIsSet = React.useRef(false);
  const [rect, setRect] = React.useState<DOMRect | undefined>(undefined);
  const onChangeRef = React.useRef(options?.onChange);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    onChangeRef.current = options?.onChange;
    if (nodeRef.current !== element) {
      setElement(nodeRef.current);
    }
  });

  useLayoutEffect(() => {
    if (element && !initialRectIsSet.current) {
      initialRectIsSet.current = true;
      setRect(element.getBoundingClientRect());
    }
  }, [element]);

  useLayoutEffect(() => {
    let elem = element;
    // State initializes before refs are placed, meaning the element state will
    // be undefined on the first render. We still want the rect on the first
    // render, so initially we'll use the nodeRef that was passed instead of
    // state for our measurements.
    if (!initialRefIsSet.current) {
      initialRefIsSet.current = true;
      elem = nodeRef.current;
    }

    if (!elem) {
      if (__DEV__) {
        console.warn('You need to place the ref');
      }
      return;
    }

    const observer = observeRect(elem, (rect) => {
      onChangeRef.current?.(rect);
      setRect(rect);
    });
    observer.observe();
    return () => {
      observer.unobserve();
    };
  }, [element, nodeRef]);

  return rect || null;
}

/**
 * @see Docs https://reach.tech/rect#userect
 */
type UseRectOptions = {
  /**
   * Tells `Rect` to observe the position of the node or not. While observing,
   * the `children` render prop may call back very quickly (especially while
   * scrolling) so it can be important for performance to avoid observing when
   * you don't need to.
   *
   * This is typically used for elements that pop over other elements (like a
   * dropdown menu), so you don't need to observe all the time, only when the
   * popup is active.
   *
   * Pass `true` to observe, `false` to ignore.
   *
   * @see Docs https://reach.tech/rect#userect-observe
   */
  observe?: boolean;
  /**
   * Calls back whenever the `rect` of the element changes.
   *
   * @see Docs https://reach.tech/rect#userect-onchange
   */
  onChange?: (rect: PRect | undefined) => void;
};

type PRect = Partial<DOMRect> & {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
};

/**
 *
 * Measures DOM elements (aka. bounding client rect). This is cloned from the last available version of `@reach/userect` with some modifications made
 * @link https://reach.tech/rect/#userect
 * @see getBoundingClientRect https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
 * @see Source                https://github.com/reach/reach-ui/blob/main/packages/rect/src/reach-rect.tsx
 */
function useRect({
  ref,
  onResize,
}: {
  ref: Parameters<typeof useRectBase>[0];
  onResize?: (rect: DOMRect) => void;
}) {
  return useRectBase(ref, {
    onChange(rect) {
      onResize?.(rect as DOMRect);
    },
  });
}

////////////////////////////////////////////////////////////////////////////////
// Exports

export type { PRect, UseRectOptions, RectProps };
export { Rect, useRect };
