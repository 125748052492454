import { useState, useRef } from 'react';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import { DialogWithContent } from '../dialog-with-content';
import { ResponsiveImage, getCurrentAspectRatioAndUrl } from '@dx-ui/osc-responsive-image';
import type { TLayout } from '../common.types';
import type { TFullWidthImage } from './full-width-image.types';
import { FullWidthImageDialogAspectRatio } from './full-width-image.types';
import { Icon } from '@dx-ui/osc-icon';

export type FullWidthImageProps = TLayout & TFullWidthImage;

/**
 * FullWidthImage renders an image at the full width of the content, includes text overlay that opens a modal.
 *
 * Multiple imageUrls are provided, and displayed conditionally at differnent breakpoints. Clicking the FullWidthImage will open a DialogWithContent
 */
export const FullWidthImage: React.FC<FullWidthImageProps> = ({
  id,
  desktopImageUrl,
  tabletImageUrl,
  mobileImageUrl,
  imageAltText,
  captionData,
  imageOverlayButtonText,
  title,
  description,
  link,
  overlayButtonClicked,
  brandComponentTheme = 'HH',
  ...props
}) => {
  const [showInfoContainer, setShowInfoContainer] = useState(false);
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });

  const [imgLoaded, setImgLoaded] = useState(false);

  const isThemeAdded = !!brandComponentTheme;

  const handleImageOverlayButtonClick = () => {
    setShowInfoContainer(true);
    overlayButtonClicked && overlayButtonClicked();
  };
  const handleCloseButtonClick = () => {
    setShowInfoContainer(false);
  };

  if (!desktopImageUrl || !tabletImageUrl || !mobileImageUrl) {
    return null;
  }

  const { imageUrl, aspectRatio } = getCurrentAspectRatioAndUrl({
    width: rect?.width || 0,
    imageUrlMobile: mobileImageUrl,
    imageUrlTablet: desktopImageUrl,
    imageUrlDesktop: desktopImageUrl,
    aspectRatioMobile: '3:2',
    aspectRatioTablet: '18:5',
    aspectRatioDesktop: '18:5',
  });

  return (
    <div
      id={id}
      ref={ref}
      data-testid="fullWidthImage"
      className={cx('container pt-16 pb-10 md:pl-8 md:pr-6 lg:pt-20 lg:pb-16', {
        'pt-20 pb-14 lg:pt-24 lg:pb-20': isThemeAdded,
      })}
    >
      <div
        data-testid="fullwidth-image-wrap"
        className="relative flex flex-col justify-center overflow-hidden"
      >
        <div className={cx('absolute flex h-full w-full items-end', { 'z-2': imgLoaded })}>
          <div className="from-bg-inverse size-full bg-gradient-to-t to-transparent">
            <button
              className="text-text-overlay brand-es-refresh:font-headline brand-es-refresh:font-normal brand-ey:text-text-inverse brand-gu:font-headline brand-gu:font-normal brand-ou:text-text-inverse brand-ou:font-headline brand-ou:font-normal absolute bottom-2 right-4 flex h-14 cursor-pointer items-center border-0 text-right text-xl font-bold leading-5 md:bottom-8 lg:right-8 lg:text-4xl"
              type="button"
              onClick={handleImageOverlayButtonClick}
              data-testid="overlayButton"
            >
              <span>{imageOverlayButtonText}&nbsp;</span>
              <Icon
                className="-mx-4 size-12 xl:size-16 rtl:-scale-x-100"
                name="arrow-small-right"
              />
            </button>
          </div>
        </div>

        <ResponsiveImage
          id={`${id}-img`}
          aspectRatio={aspectRatio}
          imageUrl={imageUrl}
          altText={imageAltText || ''}
          width={rect?.width ?? 0}
          onImgLoaded={() => setImgLoaded(true)}
          captionData={captionData}
        />
      </div>

      <DialogWithContent
        data-testid="fullWidthImageModalDialog"
        id="fullWidthImageModalOverlay"
        isOpen={showInfoContainer}
        containerAspectRatio={FullWidthImageDialogAspectRatio}
        imageAspectRatio={FullWidthImageDialogAspectRatio}
        imageUrls={{ desktopImageUrl, mobileImageUrl, tabletImageUrl }}
        captionData={captionData}
        imageAltText={`${imageAltText} modal`}
        title={title}
        headline={title}
        content={description}
        link={link}
        onClose={handleCloseButtonClick}
        themeWrapperProps={{ brandCodeForTheme: brandComponentTheme }}
        {...props}
      />
    </div>
  );
};
