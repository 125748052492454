import { Header as DxUiHeader, type UserLinks, type TDrawerItem } from '@dx-ui/osc-header';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { getGuestFirstName, useAuth } from '@dx-ui/framework-auth-provider';
import type { LoginResponse } from '@dx-ui/osc-login';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { sendReward } from '@dx-ui/framework-conductrics';
import type { Maybe } from '@dx-ui/gql-types';
import { useProgramMember } from './use-program-member';

type GuestInfo = ReturnType<typeof useAuth>['guestInfo'];

function getUserFromGuestInfo(guestInfo: GuestInfo) {
  if (!guestInfo) {
    return undefined;
  }

  const hHonorsSummary = guestInfo?.hhonors?.summary;
  const hHonorsNumber = guestInfo?.hhonors?.hhonorsNumber;
  const hHonorsProgramAccountSummary = guestInfo?.hhonors?.programAccountSummary;
  const hHonorsIsHGVMax = Boolean(guestInfo?.hhonors?.isOwnerHGVNew);
  const hHonorsIsTeamMember = Boolean(guestInfo?.hhonors?.isTeamMember);

  return {
    name: getGuestFirstName({ guestInfo }),
    honorsTier: hHonorsSummary?.tierName || '',
    honorsPoints: hHonorsSummary?.totalPoints || 0,
    hhonorsNumber: hHonorsNumber || '',
    honorsPointsFmt: hHonorsSummary?.totalPointsFmt || '',
    isHGVMax: hHonorsIsHGVMax || false,
    isTeamMember: hHonorsIsTeamMember || false,
    honorsPrograms: hHonorsProgramAccountSummary || [],
  };
}

function useDxUiHeaderData(
  guestInfo: GuestInfo,
  brandCode: string,
  languageCode: string,
  formalBrandName = 'Hilton',
  brandUrl = '/',
  isMegaNav: boolean,
  {
    honorsSignInUrl,
    honorsJoinUrl,
    forgotInfoUrl,
    globalPrivacyUrl,
    honorsAccountUrl,
    ohwBaseUrl,
  }: {
    honorsSignInUrl: string;
    honorsJoinUrl: string;
    forgotInfoUrl: string;
    globalPrivacyUrl: string;
    honorsAccountUrl: string;
    ohwBaseUrl: string | undefined;
  },
  isSMBMember = false
) {
  const { t } = useTranslation('osc-header');
  const brandName = brandCode === 'WW' ? 'Hilton For the Stay' : formalBrandName; // `Hilton For The Stay` text is only for portfolio pages

  const brand = {
    code: brandCode,
    name: brandName,
    url: brandUrl,
  };

  const user = getUserFromGuestInfo(guestInfo);

  const hgvLink = {
    label: t('hgvMaxRateDetails'),
    url: `https://help.hilton.com/s/article/HGV-Max-rate`,
  };

  const smbMemberLink = {
    label: t('manageHiltonForBusiness'),
    url: `${ohwBaseUrl}${languageCode}/business/manage/`,
  };

  const goHiltonLink = {
    label: t('goHiltonProgramDetails'),
    url: `https://help.hilton.com/s/article/What-is-the-Go-Hilton-employee-program`,
  };

  const manageFriendsAndFamilyLink = {
    label: t('manageFriendsAndFamily'),
    url: `https://tmtp.hilton.com/tmtp/main.html`,
  };

  const userLinks: UserLinks = {
    signInLink: { url: honorsSignInUrl },
    signUpLink: { url: honorsJoinUrl },
    accountLink: { url: honorsAccountUrl },
    additionalLinks: isMegaNav
      ? [
          {
            label: t('activity'),
            url: `${ohwBaseUrl}${languageCode}/hilton-honors/guest/activity/`,
          },
          {
            label: t('pointsLabel'),
            url: `${ohwBaseUrl}${languageCode}/hilton-honors/guest/points/`,
          },
          {
            label: t('profile'),
            url: `${ohwBaseUrl}${languageCode}/hilton-honors/guest/profile/`,
          },
          ...(user?.isTeamMember ? [goHiltonLink, manageFriendsAndFamilyLink] : []),
          ...(user?.isHGVMax ? [hgvLink] : []),
          ...(isSMBMember ? [smbMemberLink] : []),
        ]
      : undefined,
  };

  const loginOptions = {
    options: {
      showCreatePassword: true,
      forgetInfoLink: { url: forgotInfoUrl },
      secureInfoLink: { url: globalPrivacyUrl },
      joinLink: { url: honorsJoinUrl, underline: false },
    },
  };

  return {
    brand,
    user,
    userLinks,
    loginOptions,
  };
}

export const goUserTypes = ['TMH', 'TPH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF'];
export const SIGN_IN_SUCCESS = 'g-IqzR2S0Ai5';

export type BrandsHeaderProps = {
  programAccountId: Maybe<number> | undefined;
  megaNavLinks: TDrawerItem[] | undefined;
  languageCode: string;
  brandCode: string;
  brandFormalName: string | undefined;
  brandUrl: string | undefined;
  showLanguageSelector: boolean;
  honorsSignInUrl: string;
  honorsJoinUrl: string;
  forgotInfoUrl: string;
  globalPrivacyUrl: string;
  honorsAccountUrl: string;
  ohwBaseUrl: string | undefined;
  heading?: string;
  trackUserLoggedIn: (
    data: {
      hhonorsNumber: string;
      tierName: string;
      points: string;
      goUserTypes: string[] | null;
    }[]
  ) => void;
  mainNavLinks:
    | {
        _id: string;
        adaDescription: string;
        campaignId?: string;
        isNewWindow: boolean;
        label: string;
        url: string;
      }[]
    | undefined;
};

export function BrandsHeader({
  programAccountId,
  trackUserLoggedIn,
  languageCode,
  brandCode,
  mainNavLinks,
  megaNavLinks,
  brandFormalName,
  brandUrl,
  showLanguageSelector,
  ohwBaseUrl,
  honorsSignInUrl,
  honorsJoinUrl,
  forgotInfoUrl,
  globalPrivacyUrl,
  honorsAccountUrl,
  heading,
}: BrandsHeaderProps) {
  const { login, logout, guestInfo, isLoading } = useAuth();
  const router = useRouter();
  const [trackSignIn, setTrackSignIn] = useState(false);
  const member = useProgramMember({ programAccountId });

  const isSMBMember = Boolean(member?.role && ['admin', 'owner', 'employee'].includes(member.role));

  const { brand, user, userLinks, loginOptions } = useDxUiHeaderData(
    guestInfo,
    brandCode,
    languageCode,
    brandFormalName,
    brandUrl,
    !!megaNavLinks,
    {
      honorsSignInUrl,
      honorsJoinUrl,
      forgotInfoUrl,
      globalPrivacyUrl,
      honorsAccountUrl,
      ohwBaseUrl,
    },
    isSMBMember
  );

  const theme = ['GU', 'ND'].includes(brandCode) ? 'dark' : undefined;

  const findStayLink: React.ComponentProps<typeof DxUiHeader>['findStayLink'] = {
    url: `${ohwBaseUrl}${languageCode}/book/reservation/find/`,
  };

  const onSignInAttempt = React.useCallback(
    (data: LoginResponse): Promise<void> => {
      const isError = !!data.error;

      if (!isError) {
        sendReward(SIGN_IN_SUCCESS);
      }
      if (data) {
        setTrackSignIn(true);
        return login(data);
      }
      return Promise.reject();
    },
    [login, setTrackSignIn]
  );

  const onSignOut = React.useCallback((): Promise<void> => logout(), [logout]);

  useEffect(() => {
    if (trackSignIn && !isLoading) {
      // We only want this to trigger once when a user has successfully signed in
      setTrackSignIn(false);
      const availableGoUserTypes: string[] | null = guestInfo?.hhonors?.packages?.filter
        ? guestInfo?.hhonors?.packages
            ?.filter((pack) => pack?.packageName && goUserTypes.includes(pack.packageName))
            ?.map((pack) => pack && pack.packageName)
        : null;

      trackUserLoggedIn([
        {
          hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber ?? '',
          tierName: guestInfo?.hhonors?.summary?.tierName ?? '',
          points: guestInfo?.hhonors?.summary?.totalPointsFmt ?? '',
          goUserTypes: availableGoUserTypes,
        },
      ]);
    }
  }, [guestInfo, isLoading, trackSignIn, trackUserLoggedIn]);

  return (
    <DxUiHeader
      theme={theme}
      brand={brand}
      megaNavLinks={megaNavLinks}
      mainNavLinks={mainNavLinks}
      onSignInAttempt={onSignInAttempt}
      onSignOut={onSignOut}
      user={user}
      userLinks={userLinks}
      loginOptions={loginOptions}
      findStayLink={findStayLink}
      heading={heading}
      {...(showLanguageSelector && {
        languageSelectorOptions: {
          appName: 'dx-cpm-live',
          locale: router.locale || 'en',
          ...(megaNavLinks && {
            labelOptions: {
              className: 'text-sm',
            },
            buttonOptions: {
              className: 'text-sm',
            },
          }),
        },
      })}
    />
  );
}
