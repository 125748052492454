import { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { addDays } from 'date-fns';
import { useRouter } from 'next/router';
import type { AdditionalQSParameters, FormDataValues } from '@dx-ui/osc-shop-form';
import {
  ShopFormDates,
  ShopFormGroup,
  ShopForm,
  ShopFormLocation,
  ShopFormRooms,
  ShopFormSpecialRates,
  wrapperClassNameDefaultWithLocation,
} from '@dx-ui/osc-shop-form';
import { useFormContext } from 'react-hook-form';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useLocation } from '@dx-ui/framework-location-provider';
import type { TBrandLayout, TLayout } from '@dx-ui/osc-marketing';
import type { SpecialRates } from '@dx-ui/osc-special-rates';
import { sendReward } from '@dx-ui/framework-conductrics';

import { useRecentSearchPopularDestinationData } from './use-location-options-data';
import {
  MY_FLEXIBLE_DATES_CLICK_GOAL,
  RESET_DATES,
  useGetMVTSelections,
} from './use-get-mvt-selections';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export const CONDUCTRICS_EDIT_BRAND_SEARCH_WIDGET = 'g-C6bnRUoR2d';

export const getAddress = (
  defaultLanguage: string,
  languageCode: string,
  query: string,
  location: { description: string } | null
) => (languageCode !== defaultLanguage ? location?.description || query : query) || '';

export type FormData = FormDataValues;
export type SearchData = {
  formData: FormData;
  url?: string | null;
};

interface SearchProps extends TLayout, TBrandLayout {
  additionalQSParameters: AdditionalQSParameters;
  autocompleteUri: string;
  defaultLanguage: string;
  languageCode: string;
  onSearchSubmit: (formData: FormData, url: string) => void;
  sessionId: string;
  specialRates: Partial<SpecialRates>;
  isDayUse: boolean;
  baseAppUrl: string;
  isPartnerBrand: boolean;
  maxNumRooms?: number;
}

const isPortfolioPage = (baseAppUrl: string, disableOptionalDates: boolean) => {
  return (
    !disableOptionalDates && !(baseAppUrl.includes('/brands/') || baseAppUrl.includes('/events/'))
  );
};

/**
 * BrandsSearch composes the ShopFrom components together into the brands-specific layout
 * used on all brands pages
 */
export const BrandsSearch: React.FC<SearchProps> = ({
  onSearchSubmit,
  autocompleteUri,
  additionalQSParameters,
  sessionId,
  languageCode,
  brandCode,
  isGroupSearch = false,
  specialRates,
  isDayUse,
  baseAppUrl,
  isPartnerBrand,
  maxNumRooms,
}) => {
  const { guestInfo } = useAuth();

  const { asPath, isReady } = useRouter();
  const [smbRate, setSmbRate] = useState(false);
  const isSmbMember = !!guestInfo?.hhonors?.isSMBMember;

  useEffect(() => {
    if (isReady) {
      const searchParams = new URLSearchParams(asPath.split('?')[1]);
      const nextSmbRate = searchParams.get('smbRate') === 'true' && isSmbMember;
      if (nextSmbRate !== smbRate) {
        setSmbRate(nextSmbRate);
      }
    }
  }, [asPath, isReady, smbRate, isSmbMember]);

  const now = useMemo(() => new Date(Date.now()), []);
  const startdate = useMemo(() => (isGroupSearch ? addDays(now, 7) : now), [isGroupSearch, now]);
  const enddate = useMemo(
    () => (isGroupSearch ? addDays(startdate, 2) : addDays(startdate, 1)),
    [isGroupSearch, startdate]
  );

  //Disable optional dates for '/portal/travel-agents', '/p/luxury', '/preferred-rates', and events
  //todayAsDefault=true in SearchDates will disable optional dates
  const disableOptionalDates =
    baseAppUrl.includes('/portal/travel-agents') ||
    baseAppUrl.includes('/p/luxury') ||
    baseAppUrl.includes('/preferred-rates') ||
    isGroupSearch;

  const defaultValues = {
    brandCode,
    specialRates: smbRate ? { ...specialRates, smbRate } : specialRates,
    hhonors: guestInfo?.hhonors,
    dates: disableOptionalDates
      ? {
          arrivalDate: startdate,
          departureDate: enddate,
          datesFlex: false,
        }
      : {},
    sessionToken: sessionId,

    ...(isGroupSearch ? { numRooms: 10, meetingSpace: false, numAttendees: 0 } : {}),
  };

  const onFormSubmitHandler = (ShopFormdata: SearchData) => {
    const { formData, url } = ShopFormdata;
    const redirectSearchUrl = url || '';

    onSearchSubmit(formData, redirectSearchUrl);
  };

  const searchFormKey = encodeURI(JSON.stringify(defaultValues));
  const validateFormOnSubmit = isPortfolioPage(baseAppUrl, disableOptionalDates);
  return (
    <div className="searchWidgetBrand brand-lx:bg-bg-light" data-testid="searchWidgetBrand">
      <div className="container mx-auto my-0 py-4">
        <ShopForm
          language={languageCode}
          onSubmit={onFormSubmitHandler}
          defaultValues={defaultValues}
          additionalQSParameters={additionalQSParameters}
          targetOHWPage="search"
          key={searchFormKey}
          wrapperClassName={cx([...wrapperClassNameDefaultWithLocation.slice(1), 'gap-3'])}
          canAlwaysSubmit={true}
          enableDatesFlexDreamRouting={true}
          shouldDisplayGeocodeErrorModal={true}
          hasErrorBanner={false}
          {...(validateFormOnSubmit ? { useFormProps: { mode: 'onSubmit' } } : {})}
        >
          <SearchWidgetForm
            languageCode={languageCode}
            sessionId={sessionId}
            autocompleteUri={autocompleteUri}
            isGroupSearch={isGroupSearch}
            isDayUse={isDayUse}
            disableOptionalDates={disableOptionalDates}
            baseAppUrl={baseAppUrl}
            isPartnerBrand={isPartnerBrand}
            maxNumRooms={maxNumRooms}
          />
        </ShopForm>
      </div>
    </div>
  );
};

function SearchWidgetForm({
  languageCode,
  sessionId,
  autocompleteUri,
  isGroupSearch,
  isDayUse,
  disableOptionalDates,
  baseAppUrl,
  isPartnerBrand,
  maxNumRooms,
}: Pick<
  SearchProps,
  | 'languageCode'
  | 'sessionId'
  | 'autocompleteUri'
  | 'isDayUse'
  | 'baseAppUrl'
  | 'isGroupSearch'
  | 'isPartnerBrand'
  | 'maxNumRooms'
> & { disableOptionalDates: boolean }) {
  const { coordinate, country } = useLocation();

  const { t } = useTranslation(['osc-rooms', 'osc-location']);
  const { asPath } = useRouter();
  const isDatesRequired = asPath.includes('/preferred-rates/') || asPath.includes('/events/');

  //NHCSEARCH-5088 Remove Flexible dates when reset date is present
  const [enableRemoveFlexibleDatesTest, setEnableRemoveFlexibleDatesTest] = useState(false);

  //NHCSEARCH-5194 enable test for country = CA or GB or US
  const [enableCountryPopularDestinationTest, setEnableCountryPopularDestinationTest] =
    useState(false);
  const abTestCountry = useMemo(() => {
    const userCountry = !!country && ['CA', 'GB', 'US'].includes(country) ? country : undefined;
    if (userCountry) window.sessionStorage.setItem('user-location', userCountry);
    else if (isBrowser) window.sessionStorage.removeItem('user-location');
    return userCountry;
  }, [country]);

  const {
    isRemoveFlexibleDatesTestLoaded,
    isRemoveFlexibleDatesDefault,
    isRemoveFlexibleDatesVariant,
    isCountryBasedPopularDestinationsTestLoaded,
    isCountryPopularDestinationVariant,
  } = useGetMVTSelections({
    REMOVE_FLEXIBLE_DATES_AGENT_ID: enableRemoveFlexibleDatesTest,
    COUNTRY_BASED_POPULAR_DESTINATIONS_AGENT_ID: enableCountryPopularDestinationTest,
  });

  //Popular Destinations and Recent Searches applies to portofolio page only
  const enablePopularDestinationsAndRecentSearches = isPortfolioPage(
    baseAppUrl,
    disableOptionalDates
  );

  const { showRecentSearches: enableRecentSearches, popularDestinationOptions } =
    useRecentSearchPopularDestinationData({
      isPopularDestinations: enablePopularDestinationsAndRecentSearches,
      isRecentSearches: enablePopularDestinationsAndRecentSearches,
      country: isCountryPopularDestinationVariant ? abTestCountry : undefined,
      languageCode,
    });

  const {
    formState: { touchedFields },
  } = useFormContext();

  const isTouched = Object.keys(touchedFields).length !== 0;

  const isSearchRewardSent = useRef(false);

  const sendSearchReward = () => {
    if (!isSearchRewardSent.current) {
      isSearchRewardSent.current = true;
      sendReward(CONDUCTRICS_EDIT_BRAND_SEARCH_WIDGET);
    }
  };

  useEffect(() => {
    if (isTouched) {
      sendSearchReward();
    }
  }, [isTouched]);

  return (
    <>
      <ShopFormLocation
        language={languageCode}
        coordinate={coordinate}
        sessionId={sessionId}
        required={true}
        autocompleteUri={autocompleteUri}
        enableRecentSearches={enableRecentSearches}
        popularDestinationOptions={popularDestinationOptions || []}
        registerOptions={{
          validate: () => true,
        }}
        onClick={() => {
          //NHCSEARCH-5194 enable test
          if (
            !enableCountryPopularDestinationTest &&
            !!abTestCountry &&
            isCountryBasedPopularDestinationsTestLoaded
          ) {
            setEnableCountryPopularDestinationTest(true);
          }
        }}
      />
      <ShopFormDates
        isDayUse={isDayUse}
        language={languageCode}
        hasTodayAsDefault={disableOptionalDates}
        labelOptions={{ whenLabel: true, requiredDates: isDatesRequired }}
        onOpen={() => {
          sendSearchReward();
          //NHCSEARCH-5088
          const isEventsPage = asPath.includes('/events/');
          if (isRemoveFlexibleDatesTestLoaded && !disableOptionalDates && !isEventsPage)
            setEnableRemoveFlexibleDatesTest(true);
        }}
        {...(isRemoveFlexibleDatesVariant ? { hideFlexDates: true } : {})}
        onFlexibleDates={() => {
          //NHCSEARCH-5088
          if (isRemoveFlexibleDatesDefault) sendReward(MY_FLEXIBLE_DATES_CLICK_GOAL);
        }}
        onResetDates={() => {
          //NHCSEARCH-5088
          if (isRemoveFlexibleDatesDefault || isRemoveFlexibleDatesVariant) sendReward(RESET_DATES);
        }}
      />
      {isGroupSearch ? (
        <ShopFormGroup showNumAttendees={true} />
      ) : (
        <>
          <ShopFormRooms
            occupancyLimitMessage={t('osc-rooms:occupancy.occupancyLimitMessage')}
            hideGroupLink={!!isPartnerBrand}
            onOpen={() => {
              sendSearchReward();
            }}
            maxRooms={maxNumRooms}
          />
          <ShopFormSpecialRates
            onOpen={() => {
              sendSearchReward();
            }}
          />
        </>
      )}
    </>
  );
}
