import {
  VerticalTabButton,
  VerticalTab,
  VerticalTabPanel,
  VerticalTabs,
} from '@dx-ui/osc-vertical-tabs';
import cx from 'classnames';
import Image from 'next/image';
import Icon from '@dx-ui/osc-icon';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { iconMapper } from '../../utils/icon-mapper';
import type { TVerticalTabs } from './VerticalTabs.types';
import VerticalTabBody from './VerticalTabbedSectionContent';
import { mapTextAlignToClassname } from '@dx-ui/osc-textual-block';
import { HeadingLevelProvider } from '@dx-ui/osc-heading-level';

export const VerticalTabbedSection = ({
  listHeadline,
  listDescription,
  textAlign = 'left',
  items,
  brandComponentTheme,
  onTabChange,
  id: cmsId,
}: TVerticalTabs) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const id = `tabs${cmsId}`;

  return (
    <section
      className={cx('container pb-6 pt-10 lg:py-16', {
        'tabbed-bg-dark': isDark,
        'tabbed-bg-light': isLight,
        'tabbed-bg': !isDark && !isLight,
      })}
    >
      {listHeadline ? (
        <BrandTextHeader
          id={id}
          className={cx(
            'brand-gu:text-center brand-up:text-center',
            mapTextAlignToClassname(textAlign),
            {
              'tabbed-list-headline-dark': isDark,
              'tabbed-list-headline-light': isLight,
            }
          )}
        >
          {listHeadline}
        </BrandTextHeader>
      ) : null}

      {listDescription ? (
        <BrandTextBody
          className={cx(
            'pb-8',
            'brand-gu:text-center brand-up:text-center',
            mapTextAlignToClassname(textAlign),
            {
              'tabbed-list-description-dark': isDark,
              'tabbed-list-description-light': isLight,
              'tabbed-list-description': !isDark && !isLight,
            }
          )}
          brandComponentTheme={brandComponentTheme}
        >
          {listDescription}
        </BrandTextBody>
      ) : null}

      <VerticalTabs
        defaultActiveId={items[0]?.id}
        id={id}
        accordionExpandedClassName={cx('!font-normal border-b', {
          'vertical-tabbed-accordion-expanded-light': isLight,
          'vertical-tabbed-accordion-expanded-dark': isDark,
          'vertical-tabbed-accordion-expanded': !isDark && !isLight,
        })}
        accordionCollapsedClassName="font-normal"
        accordionButtonWrapperClassName={cx('last:has-[button]:border-b', {
          'vertical-tabbed-accordion-dark': isDark,
          'vertical-tabbed-accordion-light': isLight,
          'vertical-tabbed-accordion': !isDark && !isLight,
        })}
        accordionClassName={cx('border-t', {
          'vertical-tabbed-accordion-dark': isDark,
          'vertical-tabbed-accordion-light': isLight,
          'vertical-tabbed-accordion': !isDark && !isLight,
        })}
        accordionIconIndicatorFillColor={cx({
          'vertical-tabbed-accordion-icon-indicator-fill-color-light': isLight,
          'vertical-tabbed-accordion-icon-indicator-fill-color-dark': isDark,
          'vertical-tabbed-accordion-icon-indicator-fill-color': !isDark && !isLight,
        })}
        accordionIconIndicatorExpandedFillColor={cx({
          'vertical-tabbed-accordion-icon-indicator-expanded-fill-color-light': isLight,
        })}
        onTabChange={onTabChange}
      >
        {items.map((item) => {
          const icon = item.iconType ? iconMapper[item.iconType] : null;

          return (
            <VerticalTab key={item.tab.label} className="pb-10">
              <VerticalTabButton
                tabId={item.id}
                inactiveClassName={cx(
                  'vertical-tabbed-button-inactive-text border-b-2 first:border-t-2',
                  {
                    'vertical-tabbed-button-inactive-light': isLight,
                    'vertical-tabbed-button-inactive-dark': isDark,
                    'vertical-tabbed-button-inactive-default': !isDark && !isLight,
                  }
                )}
                activeClassName={cx(
                  "vertical-tabbed-button-active-text z-10 border-b-2 first:border-t-2 before:content-[''] before:absolute before:start-0 before:h-full before:w-2",
                  {
                    'vertical-tabbed-button-active-dark': isDark,
                    'vertical-tabbed-button-active-light': isLight,
                    'vertical-tabbed-button-active-default': !isDark && !isLight,
                  }
                )}
                className="w-80"
              >
                <div className="flex items-center lg:flex-row">
                  {icon ? (
                    <Icon name={icon} size="lg" className="!rotate-0" />
                  ) : item.logoUrl ? (
                    <Image
                      width={50}
                      height={50}
                      src={item.logoUrl}
                      alt=""
                      unoptimized={true}
                      aria-hidden
                    />
                  ) : null}

                  {item.tab.label ? (
                    <span className="ms-3.5 pe-6 text-lg">{item.tab.label}</span>
                  ) : null}
                </div>
              </VerticalTabButton>

              <VerticalTabPanel
                tabPanelId={item.id}
                className={cx('-translate-x-1 border-t-2 ps-10 pt-8 rtl:translate-x-1', {
                  'vertical-tabbed-panel-border-dark': isDark,
                  'vertical-tabbed-panel-border-light': isLight,
                  'vertical-tabbed-panel-border': !isDark && !isLight,
                })}
              >
                <HeadingLevelProvider increaseLevel={!!listHeadline}>
                  <VerticalTabBody
                    imageUrl={item.panel.image?.url}
                    captionData={item.panel.image?.captionData}
                    altText={item.panel.image?.alt}
                    headline={item.panel.headline}
                    text={item.panel.copy}
                    brandComponentTheme={brandComponentTheme}
                    link={item.panel.link}
                  />
                </HeadingLevelProvider>
              </VerticalTabPanel>
            </VerticalTab>
          );
        })}
      </VerticalTabs>
    </section>
  );
};
