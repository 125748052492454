import type { Maybe } from '@dx-ui/gql-types';
import {
  buildDatesParams,
  buildRatesParams,
  buildRoomsParams,
} from '../uri-builder-utils/uri-builder.utils';
import type { Dates, Rates, Room } from '../types';

export type PropertyUrlParams = {
  dates?: Dates;
  numAttendees?: number | null;
  numRooms?: number | null;
  query?: string;
  rates?: Rates;
  redeemPts?: boolean;
  rooms?: Room[];
  sessionToken?: string;
  specialRateTokens?: string[];
  displayCurrency?: string | null;
};
/**
 * Returns an OHW formatted property URL
 *
 * @param {PropertyUrlParams} urlParams
 *
 */

export const propertyUriBuilder = ({
  baseUrl,
  urlParams,
}: {
  baseUrl?: Maybe<string>;
  urlParams?: PropertyUrlParams;
}) => {
  if (!urlParams || !Object.keys(urlParams).length) return baseUrl;
  const { dates, rooms, rates, specialRateTokens, numRooms, sessionToken, displayCurrency } =
    urlParams ?? {};

  const params = new URLSearchParams();
  if (dates) buildDatesParams(params, dates);
  if (rooms) buildRoomsParams(params, numRooms ? numRooms : rooms.length, rooms);
  if (displayCurrency) params.append('displayCurrency', displayCurrency);
  if (rates) buildRatesParams(params, rates);
  if (specialRateTokens && !!specialRateTokens.length)
    params.append('specialRateTokens', specialRateTokens.join(','));
  if (sessionToken) params.append('sessionToken', sessionToken);

  const queryString = params.toString();
  return `${baseUrl}?${queryString}`;
};
