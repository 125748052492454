import { useRouter } from 'next/router';

import { useAuth } from '@dx-ui/framework-auth-provider';

import { useSmb_MemberIdAndStatusQuery, useSmb_ProgramAccountMemberQuery } from './gql/queries';

export function useProgramMember({
  programAccountId,
  shouldFetchSmbMemberIdAndStatus = false,
}: Partial<{
  programAccountId: number | null | undefined;
  shouldFetchSmbMemberIdAndStatus: boolean;
}> = {}) {
  const { guestId, guestInfo } = useAuth();
  const { locale = 'en', query } = useRouter();

  const programAccountIdParam = isNaN(Number(query?.programAccountId))
    ? undefined
    : Number(query?.programAccountId);
  const accountId = programAccountId || programAccountIdParam;
  const { data, isLoading } = useSmb_MemberIdAndStatusQuery(
    { guestId: guestId as number, language: locale },
    {
      enabled: !!guestId && (!!guestInfo?.hhonors?.isSMBMember || shouldFetchSmbMemberIdAndStatus),
    }
  );

  let mostRelevantProgramAccount = data?.programAccounts?.[0];

  if ((data?.programAccounts?.length || 0) > 1 && accountId) {
    mostRelevantProgramAccount = data?.programAccounts.filter(
      (programAccount) => programAccount?.accountId === accountId
    )?.[0];
  }

  const { data: programAccountData, isLoading: memberDataLoading } =
    useSmb_ProgramAccountMemberQuery(
      {
        guestId: guestId as number,
        language: locale,
        input: {
          accountId: accountId || (mostRelevantProgramAccount?.accountId as number),
          memberId: mostRelevantProgramAccount?.memberId as number,
        },
      },
      {
        enabled:
          !!guestId &&
          !!mostRelevantProgramAccount?.memberId &&
          Boolean(accountId || mostRelevantProgramAccount?.accountId),
      }
    );

  const member = {
    accountId,
    ...programAccountData?.programAccountMember,
    memberDataLoading,
    isLoading,
  };
  return member;
}
