import type { ReactNode } from 'react';
import { Children, isValidElement } from 'react';
import { TabList, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import { Accordion } from '@dx-ui/osc-accordion';
import VerticalTabButton from './vertical-tab-button';
import VerticalTab from './vertical-tab';
import VerticalTabPanel from './vertical-tab-panel';
import cx from 'classnames';

export type VerticalTabProps = {
  /**
   * Styles for both the collapsed and the expanded state of the accordion
   */
  accordionClassName?: string;
  /**
   * Styles for the collapsed state of the accordion
   */
  accordionCollapsedClassName?: string;
  /**
   * Styles for the expanded state of the accordion
   */
  accordionExpandedClassName?: string;
  /**
   * Styles for the wrapper(div) of the accordion button
   */
  accordionButtonWrapperClassName?: string;
  /**
   * Controls the fill color for the accordion indicator icon
   */
  accordionIconIndicatorFillColor?: string;
  /**
   * Controls the fill color for expanded accordion icons
   */
  accordionIconIndicatorExpandedFillColor?: string;
  children: ReactNode;
  defaultActiveId?: string;
  id?: string;
  /**
   * For changing breakpoint between mobile/desktop layout
   */
  mobileDesktopBreakpoint?: 'sm' | 'md' | 'lg';
  onAccordionClick?: (value: string[]) => void;
  onTabChange?: (id?: string) => void;
};

const VerticalTabs = ({
  accordionClassName,
  accordionCollapsedClassName,
  accordionExpandedClassName,
  accordionButtonWrapperClassName,
  accordionIconIndicatorFillColor,
  accordionIconIndicatorExpandedFillColor,
  children,
  defaultActiveId,
  id,
  mobileDesktopBreakpoint = 'lg',
  onAccordionClick,
  onTabChange,
}: VerticalTabProps) => {
  const arrayChildren = Children.toArray(children);

  const tabList = Children.map(
    arrayChildren,
    (child) => isValidElement(child) && child.type === VerticalTab && child
  )?.filter(Boolean);

  // Filter out anything where the first two children are not VerticalTabButton then VerticalTabPanel
  const validTabs = Children.map(tabList, (child) => {
    if (
      isValidElement(child) &&
      child?.props?.children[0].type === VerticalTabButton &&
      child?.props?.children[1].type === VerticalTabPanel
    ) {
      return child;
    }
    return null;
  });

  const items = validTabs?.map((tab) => {
    const header = tab?.props?.children[0];
    const content = tab?.props?.children[1];

    return {
      content: <section className="px-2 py-4">{content?.props?.children}</section>,
      key: header.props?.tabId,
      collapsedClassName: accordionCollapsedClassName,
      collapsedButtonLabel: header?.props?.children,
      expandedClassName: accordionExpandedClassName,
      expandedButtonLabel: header?.props?.children,
      buttonWrapperClassName: accordionButtonWrapperClassName,
      iconIndicatorFillColor: accordionIconIndicatorFillColor,
      iconIndicatorExpandedFillColor: accordionIconIndicatorExpandedFillColor,
    };
  });

  return (
    <>
      <section
        className={cx('hidden', {
          'sm:block': mobileDesktopBreakpoint === 'sm',
          'md:block': mobileDesktopBreakpoint === 'md',
          'lg:block': mobileDesktopBreakpoint === 'lg',
        })}
      >
        <Tabs
          defaultActive={defaultActiveId}
          className="flex"
          onTabChange={onTabChange}
          changeWithArrow
          useUpDownArrows
        >
          <TabList
            className="mb-4 flex-col !overflow-x-hidden pb-2"
            aria-orientation="vertical"
            aria-labelledby={id}
          >
            {validTabs?.map((tab) => tab?.props?.children[0])}
          </TabList>

          <TabPanels className="flex-1 pt-2">
            {validTabs?.map((tab) => tab?.props?.children[1])}
          </TabPanels>
        </Tabs>
      </section>
      <section
        className={cx({
          'sm:hidden': mobileDesktopBreakpoint === 'sm',
          'md:hidden': mobileDesktopBreakpoint === 'md',
          'lg:hidden': mobileDesktopBreakpoint === 'lg',
        })}
      >
        <Accordion
          className={cx(
            'accordion-stacked hh-accordion text-primary px-5 text-left',
            accordionClassName
          )}
          {...(defaultActiveId && { defaultValue: [defaultActiveId] })}
          items={items}
          onValueChange={onAccordionClick}
          variant="stacked"
          type="multiple"
        />
      </section>
    </>
  );
};
export { VerticalTabs };
export default VerticalTabs;
