/* eslint-disable no-console */
import type { NEXT_DATA } from 'next/dist/shared/lib/utils';
import { dynatrace } from './logger';
import * as React from 'react';

declare global {
  interface Window {
    __NEXT_DATA__: NEXT_DATA;
  }
}

const DEFAULT_ACTION = 'PAGE';

export const LOG_LEVELS = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARN: 'WARN',
} as const;

export const CLIENT_ACTIONS = {
  ERROR: 'isclienterror',
  INFO: 'isclientinfo',
  WARN: 'isclientwarning',
} as const;

/**
 * Wrapper for {@link https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html#reportCustomError | reportCustomError}.
 *
 * Specifies `LOG_LEVEL` of `ERROR` which extends 'WARN' and also enables alerting in Dynatrace if the error rate is high. @see {@link https://docs.dynatrace.com/docs/platform/davis-ai/basics/terminology#alert | Davis AI Basics - Alerting}
 *
 * @param key           The key of the error. For example: 'Booking Error'
 * @param value         The error value. For example: 'Payment failed to process.'
 * @param hint          A hint to pinpoint the problem.
 *                        - By default this includes the app version.
 *                        - Anything over 100 characters will be truncated.
 *                        - The hint will appear as pipe delimited in Dynatrace.  For example: `dx-res-ui:234327|hint`
 */
export function logError(key: LogArgs[0], value: LogArgs[1], hint = '') {
  log(key, value, LOG_LEVELS.ERROR, hint);
}

/**
 * Wrapper for {@link https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html#reportCustomError | reportCustomError}.
 *
 * Specifies `LOG_LEVEL` of `WARN` which extends 'INFO' and also measures "Apdex" performance in Dynatrace.  @see {@link https://docs.dynatrace.com/docs/shortlink/apdex | Apdex Ratings}
 *
 * @param key           The key of the error. For example: 'Hotel Not Found'
 * @param value         The error value. For example: 'ctyhocn or propCode not found.'
 * @param hint          A hint to pinpoint the problem.
 *                        - By default this includes the app version.
 *                        - Anything over 100 characters will be truncated.
 *                        - The hint will appear as pipe delimited in Dynatrace.  For example: `dx-res-ui:234327|hint`
 */
export function logWarning(key: LogArgs[0], value: LogArgs[1], hint = '') {
  log(key, value, LOG_LEVELS.WARN, hint);
}

/**
 * Wrapper for {@link https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html#reportCustomError | reportCustomError}.
 *
 * Specifies `LOG_LEVEL` of `INFO` which captures the error in Dynatrace.  @see {@link https://docs.dynatrace.com/docs/shortlink/application-performance#top-errors | Top Errors} and {@link https://docs.dynatrace.com/docs/shortlink/configure-application-errors | Configure Application Errors}
 *
 * @param key           The key of the error. For example: 'Validation Error'
 * @param value         The error value. For example: 'First name does not match the regular expression.'
 * @param hint          A hint to pinpoint the problem.
 *                        - By default this includes the app version.
 *                        - Anything over 100 characters will be truncated.
 *                        - The hint will appear as pipe delimited in Dynatrace.  For example: `dx-res-ui:234327|hint`
 */
export function logInfo(key: LogArgs[0], value: LogArgs[1], hint = '') {
  log(key, value, LOG_LEVELS.INFO, hint);
}

/**
 * Custom hook to enable manual page detection in Dynatrace.
 *
 * @param {string} name - New view name. Usually it matches the location.pathname.
 * @param {string} group - The group associated with the page. The app name.
 */
export const useManualPageLogger = (name: string, group: string): void => {
  React.useEffect(() => {
    if (!dynatrace) {
      return console.warn('Dynatrace is not available.');
    }
    dynatrace.enableManualPageDetection();
    const modifiedGroup = window.location.pathname.startsWith('/en/') ? group : `${group}-NonEN`;
    const pageResult = dynatrace.setPage({ name, group: modifiedGroup });
    if (pageResult < 0) {
      console.warn('Error setting page group in Dynatrace');
    }
  });
};

type LogArgs = Parameters<typeof log>;

function log(
  key: string,
  value: Parameters<typeof stringifyValue>[0],
  level: keyof typeof LOG_LEVELS,
  hint: string
) {
  if (dynatrace) {
    const errorKey = `${level}:${key}` as const;
    const property = { [CLIENT_ACTIONS[level]]: 'true' } as const;
    const actionId = dynatrace.enterAction(DEFAULT_ACTION);
    dynatrace.addActionProperties(actionId, undefined, undefined, property);
    dynatrace.reportCustomError(errorKey, stringifyValue(value), buildHint(hint), actionId);
    dynatrace.leaveAction(actionId);
    dynatrace.sendBeacon(true, true, true);
  } else {
    const fn = level.toLowerCase() as Lowercase<typeof level>;
    console[fn](key, value, hint);
  }
}

function buildHint(hint: string) {
  const version = window.__NEXT_DATA__?.runtimeConfig?.['APP_VER'] || '';
  return [version, hint].filter(Boolean).join('|');
}

function stringifyValue(value: string | Error | Record<string, unknown>) {
  if (typeof value === 'string') {
    return value;
  }
  if (value instanceof Error) {
    return value.message;
  }
  return JSON.stringify(value);
}
