import type { EnvironmentVariables } from '@dx-ui/cpm-mapping-shared';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';

export const isBrowser: boolean = typeof window !== 'undefined';
export const getBaseUrl = (env: EnvironmentVariables, slug?: string) => {
  const baseUrl = env.HILTON_ASSETS_URI ?? '';
  return slug ? new URL(slug, baseUrl)?.href : baseUrl;
};

export const getAppVersion = (env: EnvironmentVariables) => env.APP_VER || 'dx-hotels-ui:0';

export const defaultPhoneNumbers = {
  number: '8556721138',
  friendlyNumber: '855-672-1138',
};

export const LANGUAGES = {
  EN: 'en',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
  JA: 'ja',
  ES: 'es',
  RU: 'ru',
  AR: 'ar',
  HO: 'ko',
  PT: 'pt',
  TR: 'tr',
  ZH_HANS: 'zh-hans',
  ZH_HANT: 'zh-hant',
  CS: 'cs',
  SV: 'sv',
  TH: 'th',
  NL: 'nl',
  RO: 'ro',
  PL: 'pl',
  BG: 'bg',
  FI: 'fi',
} as const;

export const getLanguages = () => Object.values(LANGUAGES) as SupportedLanguage[];

export const disableMetricsCookie = 'OHW_PROP_disable_metrics';
