const commaOverrides = {
  ar: '،',
  ja: '、',
  'zh-hans': '、',
  'zh-hant': '、',
};

export function getLocaleComma({ locale }: { locale: string }) {
  return commaOverrides[locale as keyof typeof commaOverrides] || ',';
}

export const getIntlNumberLocale = (locale: string) => {
  if (locale === 'ar') {
    return 'ar-ae';
  }
  return locale;
};

export function getLocaleFormattedNumber(
  value: number,
  { locale, options }: { locale: string; options?: Intl.NumberFormatOptions }
) {
  return Intl.NumberFormat(getIntlNumberLocale(locale), options).format(value);
}
