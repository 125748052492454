import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { getPrefersReducedMotion } from './get-prefers-reduced-motion';
import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';
import { HeadingLevel } from '@dx-ui/osc-heading-level';
import { useMediaQuery, useIntersectionObserver } from 'usehooks-ts';
import Image from 'next/image';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { overrideImageUrl } from '@dx-ui/osc-responsive-image';
import { VideoControls, VideoPlayerMarkup, useVideoPlayer } from '@dx-ui/osc-video-player';
import { type HeroVideo } from '@dx-ui/osc-hero-video';

type OscDomLink = {
  adaDescription: string;
  isNewWindow: boolean;
  label: string;
  url: string;
};

type ContentBlock =
  | { cta: OscDomLink | null; markdown: string }
  | {
      cta: OscDomLink | null;
      headline: string;
      description: string;
    };

const getIsWindowVerticallySmall = () => window.innerHeight < 700;

function Content({
  content,
  brandComponentTheme,
}: {
  content: ContentBlock;
  brandComponentTheme: CmsBrandComponentTheme | undefined;
}) {
  const isDark = brandComponentTheme === 'dark';

  if ('markdown' in content) {
    return (
      <div
        className={cx(
          'relative mt-4 w-full text-center text-text brand-wa:font-serif mb-8 mt-4 text-center lg:mt-6 lg:text-xl',
          {
            'brand-ey:text-text-inverse brand-gu:text-text-inverse': isDark,
          }
        )}
      >
        <CustomMarkdown brandComponentTheme={brandComponentTheme}>
          {content.markdown}
        </CustomMarkdown>
      </div>
    );
  } else {
    return (
      <div className={cx('relative mt-4 w-full text-center')}>
        <CustomMarkdown brandComponentTheme={brandComponentTheme}>
          {content.headline}
        </CustomMarkdown>
        <div
          className={cx('text-text brand-wa:font-serif mb-8 mt-4 text-center lg:mt-6 lg:text-xl', {
            'brand-es-refresh:text-text-inverse brand-ey:text-text-inverse brand-gu:text-text-inverse brand-nd:text-text-inverse':
              isDark,
          })}
        >
          <CustomMarkdown brandComponentTheme={brandComponentTheme}>
            {content.description}
          </CustomMarkdown>
        </div>
      </div>
    );
  }
}

const CardContentArea = ({
  animationPercent,
  content,
  brandComponentTheme,
  children,
}: {
  animationPercent: number;
  content: ContentBlock;
  brandComponentTheme: CmsBrandComponentTheme | undefined;
  children: React.ReactNode;
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div
      className={cx('bg-bg-alt m-auto max-w-full p-12 brand-ou:bg-secondary', {
        'motion-safe:animate-fadeInUp': animationPercent > 0.45 || isFocused,
        'motion-safe:animate-fadeOutUp motion-safe:opacity-0':
          animationPercent <= 0.45 && !isFocused,
        'brand-ey:bg-bg-light brand-gu:bg-bg brand-nd:bg-bg-light': isLight,
        'brand-es-refresh:!bg-bg-dark brand-ey:!bg-bg-dark brand-gu:bg-bg-dark brand-nd:bg-bg-dark':
          isDark,
        'brand-ey:bg-bg brand-gu:bg-quarternary brand-nd:bg-bg': !isDark && !isLight,
        'brand-es-refresh:bg-bg': !isDark,
      })}
    >
      {children}
      <div className="flex w-full items-center justify-center">
        {content.cta && content.cta.label && content.cta.url ? (
          <BrandLink
            label={content.cta.label}
            isNewWindow={content.cta.isNewWindow}
            showNewWindowIcon={content.cta.isNewWindow}
            url={content.cta.url}
            brandComponentTheme={brandComponentTheme}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export const ScrollAnimation = ({
  headline,
  primaryCTA,
  shortDescription,
  longDescription,
  video,
  content,
  image,
  textAlign = 'center',
  brandComponentTheme,
}: {
  primaryCTA?: {
    adaDescription: string;
    isNewWindow: boolean;
    label: string;
    url: string;
  };
  headline: string;
  shortDescription?: string;
  longDescription?: string;
  video?: Pick<
    React.ComponentProps<typeof HeroVideo>,
    'markupSchemas' | 'videoUrl' | 'isAutoPlay' | 'videoName'
  >;
  content: ContentBlock | undefined;
  textAlign?: 'left' | 'right' | 'center';
  image?: {
    altText: string;
    desktopUrl: string;
    mobileUrl: string;
  };
  brandComponentTheme?: CmsBrandComponentTheme;
}) => {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [animationPercent, setAnimationPercent] = useState(0);
  const [showInitialContent, setShowInitialContent] = useState(false);
  const [shouldAnimateWindow, setShouldAnimateWindow] = useState(false);
  const videoUrl = video?.videoUrl || '';
  const { videoProps, videoControlsProps } = useVideoPlayer({
    videoName: video?.videoName,
    videoUrl,
    isAutoPlay: video?.isAutoPlay ?? true,
    wrapperElement: videoContainerRef,
  });

  const normalise = (val: number, max: number) => val / max;

  const getXOffset = () => {
    return Math.min(animationPercent, 1) * 100;
  };
  const getYOffset = () => {
    return Math.min(animationPercent, 1) * 200;
  };

  const getBorderWidth = () => {
    return `${shouldAnimateWindow ? getXOffset() : 0}px ${
      shouldAnimateWindow ? getYOffset() : 0
    }px`;
  };

  const getButtonOffset = () => {
    return {
      start: isMobile ? '20px' : `${shouldAnimateWindow ? 50 + getYOffset() : 40}px`,
      top: isMobile ? '20px' : `${shouldAnimateWindow ? 40 + getXOffset() : 40}px`,
    };
  };

  const { ref: inViewRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: 0,
  });

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const reduceMotion = getPrefersReducedMotion();
    const verticallySmall = getIsWindowVerticallySmall();

    if (!reduceMotion) {
      document.documentElement.style.scrollBehavior = 'smooth';
    }
    if (reduceMotion || verticallySmall || isMobile) {
      setShowInitialContent(true);
    } else {
      setShouldAnimateWindow(true);
    }
    setTimeout(() => {
      setShowInitialContent(true);
    }, 1500);
    // no exhaustive dependencies needed, this should only run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!showInitialContent) setShowInitialContent(true);
      if (inView && videoContainerRef?.current && triggerRef?.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect();
        const videoRect = videoContainerRef.current.getBoundingClientRect();

        const videoHeight = videoRect.bottom - videoRect.top;
        const delta = Math.max(videoRect.bottom - triggerRect.bottom, 0);

        const animationPercent = normalise(delta, videoHeight);

        setAnimationPercent(animationPercent * 1.5);
      }
    };

    const handleResize = () => {
      if (getIsWindowVerticallySmall() || isMobile) {
        setShouldAnimateWindow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [inView, triggerRef, isMobile, showInitialContent]);

  // This is so we can put the inView ref and an HTML element ref on the same element
  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      triggerRef.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  return (
    <div className="relative mb-40 md:mb-0">
      {video?.markupSchemas ? <VideoPlayerMarkup markupSchemas={video.markupSchemas} /> : null}

      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="z-2 sticky top-0 block h-screen w-px">
        {video?.videoUrl ? (
          <div
            style={{
              insetInlineStart: getButtonOffset().start,
              top: getButtonOffset().top,
            }}
            className="absolute size-12"
          >
            <VideoControls {...videoControlsProps} buttonOptions={{ mute: { isVisible: false } }} />
          </div>
        ) : null}
      </div>
      {/* Video / Image and Windowing animation area */}
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div ref={videoContainerRef} className="z-1 sticky top-0 mt-[-100vh] block h-screen">
        <div
          style={{
            opacity: `${0.7 - Math.min(animationPercent, 1) * 0.7}`,
            borderWidth: getBorderWidth(),
          }}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="bg-bg-inverse border-bg z-1 absolute size-full"
        />
        <div className={cx('sticky top-0 z-0 block ')}>
          {videoUrl ? (
            <video
              ref={videoProps.videoElement}
              className="h-screen w-screen object-cover object-center"
              data-testid="background-video"
              title={video?.videoName}
              muted
              loop
            >
              <source data-testid="masthead-background-video__track" src={videoUrl} />
            </video>
          ) : null}
          {!videoUrl && image?.desktopUrl ? (
            <div className="h-screen w-screen">
              <Image
                priority
                fill
                alt={image.altText}
                className="hidden object-cover object-center md:block"
                src={image.desktopUrl}
                unoptimized={true}
              />
              <Image
                priority
                fill
                alt={image.altText}
                className="block object-cover md:hidden"
                src={overrideImageUrl(image.mobileUrl, 600, 500)}
                unoptimized={true}
              />
            </div>
          ) : null}
        </div>
        <div
          // Windowing effect
          style={{
            borderWidth: getBorderWidth(),
          }}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="border-bg brand-ou:border-secondary absolute inset-0 z-0 h-screen w-full overflow-hidden border-solid will-change-transform"
        />
      </div>

      {/* Use this invisible element to take overlap measurements against the video container element. Can add border to debug position. */}
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div ref={setRefs} id="observerTrigger" className="z-1 relative h-0" />
      <div
        className={cx('absolute top-0 z-1 w-full', {
          invisible: !showInitialContent,
          'visible motion-safe:animate-fadeInUp': showInitialContent,
        })}
      >
        <div className="max-h-[75vh]">
          <div
            className={cx(
              'text-text-inverse m-auto mt-[15vh] w-11/12 text-lg md:w-9/12 lg:w-8/12',
              {
                'motion-safe:animate-fadeOutUp': animationPercent > 0.15,
                'motion-safe:animate-fadeInSlow': animationPercent <= 0.15,
                'text-center': textAlign === 'center',
                'text-start': textAlign === 'left',
                'text-end': textAlign === 'right',
              }
            )}
          >
            {shortDescription ? (
              <p className="brand-wa:font-serif text-xl">{shortDescription}</p>
            ) : null}
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <HeadingLevel className="!text-text-inverse heading-3xl sm:heading-4xl lg:heading-6xl mt-4 hyphens-auto">
              {headline}
            </HeadingLevel>
            {longDescription ? (
              <p className="brand-wa:font-serif mt-6 text-lg lg:text-xl">{longDescription}</p>
            ) : null}

            {primaryCTA?.url ? (
              <div className="mt-8">
                <BrandLink
                  label={primaryCTA.label}
                  isNewWindow={primaryCTA.isNewWindow}
                  showNewWindowIcon={primaryCTA.isNewWindow}
                  url={primaryCTA.url}
                  brandComponentTheme={brandComponentTheme}
                />
              </div>
            ) : null}
          </div>
          <div
            // Line decoration
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="border-bg start-1/2 top-[25vh] mx-auto mt-16 h-[25vh] w-2 border-s-[3px] border-solid opacity-50"
          />
        </div>
      </div>
      <div className="relative min-h-[90vh]">
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="z-1 relative m-auto mt-[-20vh] w-1/12 content-center items-start justify-center">
          <div
            // Line decoration
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="border-primary brand-lx:border-brand start-1/2 top-0 m-auto my-6 h-[25vh] w-2 border-s-[3px] border-solid opacity-75"
          />
        </div>
        <div
          className={cx(
            'relative flex z-1 m-auto w-11/12 md:w-[60%] lg:w-1/2 content-center items-start justify-center min-h-min',
            { 'z-0': animationPercent < 0.85 }
          )}
        >
          {content ? (
            <CardContentArea
              animationPercent={Math.min(animationPercent, 1)}
              content={content}
              brandComponentTheme={brandComponentTheme}
            >
              <Content content={content} brandComponentTheme={brandComponentTheme} />
            </CardContentArea>
          ) : null}
        </div>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="z-1 relative m-auto w-1/12 content-center items-start justify-center pb-4">
          <div
            // Line decoration
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="border-primary brand-lx:border-brand start-1/2 m-auto my-6 h-[25vh] w-2 border-s-[3px] border-solid opacity-75"
          />
        </div>
      </div>
    </div>
  );
};
