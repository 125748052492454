import { EventEmitter } from 'events';
import type { CookieSetOptions } from 'universal-cookie';
import Cookies from 'universal-cookie';
import { setCookie } from './cookie-utils';
import type { GuestQuery, GuestQueryVariables } from './gql/types';
import { GuestDocument } from './gql/queries';
import type { AuthResponse, LoginResponse, WSO2TokenCookie } from './types';
import type { IncomingMessage, OutgoingMessage } from 'http';
import { mergeCacheControl } from '@dx-ui/framework-merge-cache-control';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { logError, logInfo } from '@dx-ui/framework-logger';

type AuthArgs = {
  authEndpoint: string;
  gqlEndpoint: string;
  request?: IncomingMessage;
  response?: OutgoingMessage | null;
  minExpiresTTL?: number;
  appId: string;
  restore?: Extract;
  ssr?: boolean;
  appName: string;
  language?: string;
};

export type Extract = {
  guestInfo?: GuestQuery | null;
  authenticationCookie: WSO2TokenCookie | null;
};

type SessionHeaders = 'hltClientMessageId' | 'referer' | 'dx-platform' | 'hltClientSessionId';

export class AuthClient extends EventEmitter {
  private cookies: Cookies;

  public authEndpoint = '';

  public gqlEndpoint = '';

  private authenticationCookie: WSO2TokenCookie | null = null;

  private userRefreshTokenPromise: null | Promise<WSO2TokenCookie | null> = null;

  private minExpiresTTL: number;

  private requestTimeoutMs = 30000;

  private appTokenPromise: null | Promise<WSO2TokenCookie | null> = null;

  private appId = '';

  private guestInfoPromise: Promise<null | GuestQuery> | null = null;

  private serverResponse: null | OutgoingMessage | undefined;

  private ssrMode = false;

  private headers: Partial<Record<SessionHeaders, string>> | null = null;

  private appName: string | null;

  private language = 'en';

  private attemptsToGetGuestInfo = 0;

  public constructor({
    authEndpoint,
    gqlEndpoint,
    minExpiresTTL,
    appId,
    request,
    response,
    restore,
    ssr,
    appName,
    language,
  }: AuthArgs) {
    super();

    this.cookies = new Cookies(request?.headers?.cookie);
    // Force refresh Cookies.cookies private property
    this.cookies.getAll();
    this.authEndpoint = authEndpoint;
    this.gqlEndpoint = gqlEndpoint;
    this.minExpiresTTL = minExpiresTTL || 5 * 60;
    this.appId = appId;
    this.ssrMode = ssr ?? false;
    this.serverResponse = response;
    this.appName = appName || null;
    this.language = language || 'en';
    // check for refresh and do it if necessary
    this.authenticationCookie = restore?.authenticationCookie ?? this.getTokensFromCookie();
    this.headers = this.getRequestHeaders(request);

    if (this.authenticationCookie?.guestId) {
      try {
        if (this.shouldRefreshToken()) {
          this.guestInfoPromise = this.refreshLoggedInUserToken().then(() => this.fetchGuestInfo());
        } else {
          this.guestInfoPromise = restore?.guestInfo
            ? Promise.resolve(restore?.guestInfo)
            : this.fetchGuestInfo();
        }
      } catch {
        this.fetchAppToken().catch((error: Error) =>
          logError(
            'FRAMEWORK_AUTH_PROVIDER',
            error,
            'Calling fetchAppToken() when guestId exists on authenticationCookie.'
          )
        );
      }
    } else if (this.isCorpUser()) {
      // this is a corp user
      if (this.shouldRefreshToken()) {
        this.refreshLoggedInUserToken().catch((error: Error) =>
          logError(
            'FRAMEWORK_AUTH_PROVIDER',
            error,
            'Calling refreshLoggedInUserToken() when shouldRefreshToken returns true and isCorpUser() is true.'
          )
        );
      }
    } else {
      this.fetchAppToken().catch((error: Error) =>
        logError(
          'FRAMEWORK_AUTH_PROVIDER',
          error,
          'Calling fetchAppToken() when guestId does not exist on authenticationCookie.'
        )
      );
    }
  }

  /**
   * @description is the user logged in (not anon user)
   */
  public getIsLoggedIn() {
    return this.isCorpUser() || !!this.authenticationCookie?.guestId;
  }

  /**
   * @description returns the guest endpoint
   */
  public getGqlEndpoint() {
    return this.gqlEndpoint;
  }

  /**
   * @description returns the auth endpoint
   */
  public getAuthEndpoint() {
    return this.authEndpoint;
  }

  /**
   * @description take all server side info, convert to json for later restore
   */
  public async extract(): Promise<Extract> {
    if (this.getIsLoggedIn()) {
      const guestInfo = await this.getGuestInfo();
      return {
        guestInfo,
        authenticationCookie: this.authenticationCookie,
      };
    }

    return {
      authenticationCookie:
        this.authenticationCookie?.tokenType === 'Bearer' ? this.authenticationCookie : null,
    };
  }

  /**
   * @description refresh the user (or app token), makes API calls to dx-auth-ui and stores a new token
   */
  public refreshToken(): Promise<null> {
    // if logged in refresh the user token,
    if (this.getAuthenticationCookie()?.guestId || this.isCorpUser()) {
      return this.refreshLoggedInUserToken().then(() => null);
    }
    // otherwise fetch app token
    return this.fetchAppToken().then(() => null);
  }

  /**
   * @description get authorization header that should be sent along with all graphql requests. If logged in guest it will use guest's token, otherwise will use anon app token
   */
  public async getAuthorizationHeader() {
    // if refreshes are in flight wait
    if (this.userRefreshTokenPromise) {
      await this.userRefreshTokenPromise;
    }
    if (this.appTokenPromise) {
      await this.appTokenPromise;
    }
    const token = this.getAuthenticationCookie();
    if (token?.tokenType && token?.accessToken) {
      return `${token?.tokenType} ${token?.accessToken}`;
    }
    // there is no token set, fallback to auth token
    const apptoken = await this.fetchAppToken();
    if (apptoken) {
      return `${apptoken?.tokenType} ${apptoken?.accessToken}`;
    }
    // todo: figure out what to return if we can't get user token or auth token
    return '';
  }

  /**
   * @description get hltClientMessageId, referer, dx-platform, and hltClientSessionId headers
   */
  public getRequestHeaders(request?: IncomingMessage) {
    const headers = {} as Partial<Record<SessionHeaders, string>>;
    request?.rawHeaders?.forEach((value, index, array) => {
      if (/^(hltClientMessageId|referer|dx-platform|hltClientSessionId)$/i.test(value)) {
        headers[value as SessionHeaders] = array[index + 1];
      }
    });
    return headers;
  }

  /**
   * @description resulting promise for guest information
   */
  public getGuestInfo() {
    return this.guestInfoPromise;
  }

  /**
   * @description get guestId
   */
  public getGuestId() {
    return this.authenticationCookie?.guestId;
  }

  private getAuthenticationCookie() {
    return this.authenticationCookie;
  }

  public isCorpUser() {
    return this.authenticationCookie?.userRealm === 'corp';
  }

  private getAppNameParam() {
    return this.appId ? `?appName=${this.appName}` : '';
  }

  private refreshLoggedInUserToken() {
    const guestId = this.getAuthenticationCookie()?.guestId;
    // no guestId, nothing to refresh
    if (!guestId && !this.isCorpUser()) {
      return Promise.resolve(null);
    }
    if (this.userRefreshTokenPromise) {
      return this.userRefreshTokenPromise;
    }
    const currentToken = this.getAuthenticationCookie();
    this.userRefreshTokenPromise = fetch(
      `${this.authEndpoint}/dx-customer/auth/${
        this.isCorpUser() ? 'corp' : 'guests'
      }/refresh${this.getAppNameParam()}`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          ...this.headers,
          'Content-type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
          Authorization: `${currentToken?.tokenType} ${currentToken?.accessToken}`,
        },
      }
    ).then(async (res) => {
      if (!res.ok) {
        // logout
        this.invalidateSession();

        return this.getAuthenticationCookie();
      }

      const json = await res.json();

      if (json.error) {
        this.invalidateSession();
        return this.getAuthenticationCookie();
      }
      const { UserClaims, ...rest } = json;

      // save to cookies
      this.setUserSessionData({
        userInfo: UserClaims,
        tokenInfo: { ...rest },
      });
      // save to this instance
      this.authenticationCookie = {
        guestId: this.authenticationCookie?.guestId || null,
        accessToken: rest.access_token,
        expiresIn: rest.expires_in,
        tokenType: rest.token_type,
        timestamp: Date.now(),
        username: this.authenticationCookie?.username || null,
        userRealm: UserClaims?.userRealm,
      };
      this.userRefreshTokenPromise = null;
      return this.getAuthenticationCookie();
    });
    return this.userRefreshTokenPromise;
  }

  public updateAccessToken(accessToken: string) {
    const currentAuthenticationCookie = this.getAuthenticationCookie();
    if (currentAuthenticationCookie) {
      const updatedWso2AuthTokenCookie = { ...currentAuthenticationCookie, accessToken };
      this.setwso2AuthTokenCookie(updatedWso2AuthTokenCookie);
      this.authenticationCookie = updatedWso2AuthTokenCookie;
    }
  }

  public async login({ data, error }: LoginResponse) {
    // let the appToken finish processing, if necessary, so that appTokenPromise, doesn't resolve and overwrite the authentication token
    if (this.appTokenPromise) {
      await this.appTokenPromise;
    }
    if (data) {
      this.setUserSessionData(data);
      this.authenticationCookie = this.authResponseToAuthCookie(data);
      this.guestInfoPromise = this.fetchGuestInfo();
      await this.guestInfoPromise;
      this.emit('loginSuccess', data);
    }
    if (error) {
      this.emit('loginFailure', error);
    }
  }

  public async logout({ redirectToLoginPage }: { redirectToLoginPage?: boolean } = {}) {
    const logoutUrl = `${this.authEndpoint}/dx-customer/auth/${
      this.isCorpUser() ? 'corp' : 'guests'
    }/logout${this.getAppNameParam()}`;
    const authorization = await this.getAuthorizationHeader();

    await fetch(logoutUrl, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        ...this.headers,
        'Content-type': 'application/json; charset=utf-8',
        Accept: 'application/json; charset=utf-8',
        Authorization: authorization,
      },
    });

    // In anonymous session user cannot be part of SMB program. Clear out value from localStorage if it exists
    if (isBrowser) {
      window.localStorage.removeItem('smbContext');
      window.localStorage.removeItem('smbProgramId');
    }

    this.invalidateSession();
    if (redirectToLoginPage) {
      this.redirectToLoginPage();
    }
  }

  private redirectToLoginPage() {
    if (typeof window !== 'undefined') {
      const url = `/${this.language}/${
        this.isCorpUser() ? `auth2/api/saml/logout/${this.appName}/` : 'hilton-honors/login/'
      }`;
      window.location.assign(url);
    }
  }

  private invalidateSession() {
    this.guestInfoPromise = null;
    this.cookies.remove('wso2AuthToken', {
      path: '/',
      domain: '.hilton.com',
      secure: false,
      sameSite: 'lax',
    });
    this.cookies.remove('authentication', {
      path: '/',
      domain: '.hilton.com',
      secure: true,
    });
    this.cookies.remove('loggedIn', {
      path: '/',
      domain: '.hilton.com',
      secure: true,
    });
    this.cookies.remove('fname', {
      path: '/',
      domain: '.hilton.com',
      secure: true,
    });

    this.authenticationCookie = null;
    this.emit('logout');
  }

  private authResponseToAuthCookie(data: AuthResponse) {
    const authenticationCookie: WSO2TokenCookie = {
      accessToken: data?.tokenInfo?.access_token,
      expiresIn: data?.tokenInfo?.expires_in,
      tokenType: data?.tokenInfo?.token_type,
      timestamp: Date.now(),
      username: data?.userInfo?.username || null,
      guestId: data?.userInfo?.guestId || null,
    };

    return authenticationCookie;
  }

  /**
   * For backwards compatability and to keep our header size small we're using wso2Cookie,
   * even though it actually contains a dx-auth-api token
   * @param data
   */
  private setWso2Cookie(data: AuthResponse) {
    if (typeof window !== 'undefined') {
      const wso2Cookie: WSO2TokenCookie = {
        accessToken: data.tokenInfo.access_token,
        expiresIn: data.tokenInfo.expires_in,
        tokenType: data.tokenInfo.token_type,
        timestamp: Date.now(),
        username: data.userInfo?.username || null,
        guestId: data.userInfo?.guestId || null,
      };
      this.setwso2AuthTokenCookie(wso2Cookie);
      // - end of the madness
    }
  }

  private setwso2AuthTokenCookie(wso2Cookie: WSO2TokenCookie) {
    // using this cookie package because we do NOT want to use encodeURIComponent when setting
    setCookie(
      'wso2AuthToken',
      JSON.stringify(wso2Cookie),
      null,
      '/',
      '.hilton.com',
      true,
      false,
      'lax'
    );
  }

  private setUserSessionData(data: AuthResponse) {
    this.setWso2Cookie(data);
    this.setCookie('loggedIn', true, {
      path: '/',
      domain: '.hilton.com',
      secure: true,
    });
  }

  private shouldRefreshToken() {
    const token = this.getAuthenticationCookie();
    if (!token) {
      return true;
    }
    const timeout = token?.expiresIn + token?.timestamp;
    return timeout ? timeout - Date.now() / 1000 <= this.minExpiresTTL : true;
  }

  private getTokensFromCookie() {
    // first read from authentication if it exists and use that
    const authenticationCookie: AuthResponse = this.cookies.get('authentication');
    if (authenticationCookie && authenticationCookie?.userInfo?.guestId) {
      const wso2AuthShape: WSO2TokenCookie = {
        accessToken: authenticationCookie.tokenInfo?.access_token,
        expiresIn: authenticationCookie?.tokenInfo?.expires_in,
        timestamp: Date.now(),
        tokenType: authenticationCookie?.tokenInfo?.token_type,
        guestId: authenticationCookie?.userInfo?.guestId,
        username: authenticationCookie?.userInfo?.username,
      };
      return wso2AuthShape;
    }

    // otherwise fallback to reading wso2AuthToken
    const wso2AuthToken: WSO2TokenCookie = this.cookies.get('wso2AuthToken');
    if (wso2AuthToken) {
      return wso2AuthToken;
    }
    return null;
  }

  private fetchAppToken() {
    if (this.ssrMode) {
      // resolve basic auth
      this.appTokenPromise = Promise.resolve({
        accessToken: Buffer.from(`${this.appId}:${this.appId}`).toString('base64'),
        expiresIn: Date.now() / 1000 + 3600,
        tokenType: 'Basic',
        timestamp: Date.now(),
        username: null,
        guestId: null,
      });
    }
    if (this.appTokenPromise) {
      return this.appTokenPromise;
    }

    this.appTokenPromise = fetch(
      `${this.authEndpoint}/dx-customer/auth/applications/token${this.getAppNameParam()}`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          ...this.headers,
          'Content-type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          app_id: this.appId,
        }),
      }
    ).then(async (resp) => {
      if (resp.ok) {
        const json = await resp.json();
        this.authenticationCookie = {
          accessToken: json.access_token,
          expiresIn: json.expires_in,
          tokenType: json.token_type,
          timestamp: Date.now(),
          username: null,
          guestId: null,
        };
        // todo: store this in session storage, not a cookie
        // this.setCookie('authentication', JSON.stringify(this.authenticationCookie), {
        //   expires: getExpireDate(30),
        //   path: '/',
        //   domain: '.hilton.com',
        // })

        this.appTokenPromise = null;
        return this.authenticationCookie;
      }
      this.appTokenPromise = null;
      return null;
    });
    return this.appTokenPromise;
  }

  private setCookie(
    name: string,
    value: unknown,
    options: CookieSetOptions = {
      path: '/',
      domain: '.hilton.com',
      secure: true,
    }
  ) {
    try {
      this.cookies.set(name, value, options);
      // eslint-disable-next-line no-empty
    } catch {}
  }

  public async refreshGuestInfo(): Promise<void> {
    this.guestInfoPromise = this.fetchGuestInfo();
    await this.guestInfoPromise;
  }

  private async fetchGuestInfo(): Promise<GuestQuery | null> {
    if (this.attemptsToGetGuestInfo > 2) {
      this.invalidateSession();
      return null;
    }
    const userAuth = this.getAuthenticationCookie();
    const guestId = userAuth?.guestId;
    const authHeader = await this.getAuthorizationHeader();
    if (!userAuth || !this.getIsLoggedIn() || !guestId) {
      return null;
    }
    const variables: GuestQueryVariables = {
      guestId,
      language: this.language,
    };
    const requestInit = {
      headers: {
        ...this.headers,
        'Content-type': 'application/json; charset=utf-8',
        Accept: 'application/json; charset=utf-8',
        Authorization: authHeader,
      },
    };
    this.attemptsToGetGuestInfo++;

    const controller = new AbortController();
    const abortTimeout = setTimeout(() => controller.abort(), this.requestTimeoutMs);
    const requestUrl = `${this.gqlEndpoint}${this.getAppNameParam()}&operationName=guest`;
    const res = await fetch(requestUrl, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({
        query: GuestDocument.operationString,
        variables,
        operationName: 'guest',
      }),
      signal: controller.signal,
    }).catch((error: Error) => {
      logInfo('AUTH_CLIENT_FETCH_GUEST_INFO', error, `fetchGuestInfo() requestUrl: ${requestUrl}`);
      return null;
    });
    clearTimeout(abortTimeout);

    if (!res) {
      return null;
    }

    if (!res.ok) {
      if (res.status === 401) {
        if (this.attemptsToGetGuestInfo <= 1) {
          await this.refreshToken().catch((error: Error) => {
            logInfo('AUTH_CLIENT_FETCH_GUEST_INFO', error, 'fetchGuestInfo()');
            return null;
          });
        } else {
          return null;
        }
      }
      return this.fetchGuestInfo();
    }
    mergeCacheControl(res, this.serverResponse || undefined);

    const json = await res.json();
    if (json.errors) {
      await this.refreshToken();
    }
    this.attemptsToGetGuestInfo = 0;

    return json.data;
  }
}
