import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { Spacer } from '@dx-ui/osc-spacer';
import { LivSmartAccent } from './illustrations/livsmart-accent';
import { LivSmartFurniture } from './illustrations/livsmart-furniture';

export default createCpmComponentDefinition(
  'Illustration Block',

  function mapComponentData({ data }) {
    return data;
  },

  function IllustrationBlock({ componentParams, mappedPage: { brandCode }, isCPMEditor }) {
    if (brandCode !== 'EY') {
      if (isCPMEditor) {
        return (
          <div className="relative my-10 text-center font-bold">
            <span className="block">
              ⚠️ Error: Illustration Block can only currently render illustrations on LivSmart brand
              pages.
            </span>
          </div>
        );
      } else {
        return null;
      }
    }

    if (componentParams.illustration) {
      switch (componentParams.illustration) {
        case 'ey-accent':
          return (
            <Spacer>
              <LivSmartAccent />
            </Spacer>
          );

        case 'ey-furniture':
          return (
            <Spacer>
              <LivSmartFurniture />
            </Spacer>
          );

        case 'none':
          return null;

        default:
          return null;
      }
    }
  }
);
