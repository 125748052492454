import * as React from 'react';
import type {
  EnvironmentVariables,
  FeatureToggles,
  GetCompanyByAccountIdQuery,
} from '@dx-ui/cpm-mapping-shared';
import {
  PreviewSegmentsContext,
  getBrandRouteParams,
  useInitializeConductrics,
  EnvironmentVariablesContext,
  FeatureTogglesContext,
  CorporateAccountContext,
  EMBASSY_REFRESH_TOGGLE_NAME,
  BrandDataContext,
} from '@dx-ui/cpm-mapping-shared';

import { Layout } from '@dx-ui/osc-marketing-layout';
import { BrandThemeWrapper } from '@dx-ui/osc-marketing';
import type { BrandQuery } from '../generated/types';
import { useRouter } from 'next/router';
import { getAppVersion, getBaseUrl } from '../utils/constants';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { GenericError } from '@dx-ui/cpm-sdk';
import { useMetrics } from '../metrics/metrics';
import { CpmRenderer } from '../generatedCpmInterface';
import { getBrandTaggingValues } from '../utils/get-brand-tagging-values';
import type { CpmData } from '../utils/fetch-server-cpm-page';
import { getIsFeatureToggleEnabled } from '@dx-ui/utilities-feature-toggles-configs';
import { getAssetPath, getBrandSvg } from '../utils/assets';
import { isBrandHomePageUrlFormat } from '../utils/routing';

interface CpmPageProps {
  brandCode: string;
  brandData?: BrandQuery['brand'];
  cpmData: CpmData;
  corporateAccount: GetCompanyByAccountIdQuery | null;
  localeCode: string;
  isCPMEditor?: boolean;
  featureToggles?: FeatureToggles;
  env: EnvironmentVariables;
  includeBackToTopButton?: boolean;
  previewSegments?: string[];
}

function ConductricsInitalizer() {
  useInitializeConductrics();

  return null;
}

/**
 * Wrapper providing a neater interface around Providers and BloomReachPage.
 */
export function CpmPage({
  brandCode,
  brandData,
  cpmData,
  corporateAccount,
  localeCode,
  featureToggles = [],
  env,
  includeBackToTopButton = true,
  previewSegments,
}: CpmPageProps): JSX.Element {
  const router = useRouter();
  const { authClient } = useAuth();

  const { language: languageCode } = new Intl.Locale(localeCode);
  const baseAppUrl = getBaseUrl(env, `${languageCode}${router.asPath}`);
  const { pathname } = new URL(baseAppUrl);
  const { brandPath: fullBrandPath } = getBrandRouteParams(router.asPath, languageCode);
  const metricsInstance = useMetrics(
    localeCode,
    fullBrandPath,
    cpmData.mappedPage,
    brandData,
    false,
    env
  );

  if (!brandCode) {
    return GenericError({
      description1: 'Page is missing `brandCode`',
      description2: '',
    });
  }

  if (!brandData) {
    return GenericError({
      description1: 'Missing brand data',
      description2: `Brand code: "${brandCode}" can't be found in "query brands"`,
    });
  }

  if (!authClient) {
    throw new Error('Unable to load authClient');
  }

  const embassyRefreshToggleEnabled = getIsFeatureToggleEnabled({
    featureToggleData: { featureToggles },
    name: EMBASSY_REFRESH_TOGGLE_NAME,
  });

  return (
    <EnvironmentVariablesContext.Provider value={env}>
      <FeatureTogglesContext.Provider value={featureToggles}>
        <CorporateAccountContext.Provider value={corporateAccount}>
          <BrandDataContext.Provider value={brandData}>
            <PreviewSegmentsContext.Provider value={previewSegments ?? []}>
              <BrandThemeWrapper
                brandCodeForTheme={brandCode === 'WW' ? 'HH' : brandCode}
                embassyRefreshToggleEnabled={embassyRefreshToggleEnabled}
              >
                <CpmRenderer
                  cpmData={cpmData}
                  sectionNames={['top', 'main', 'footer']}
                  metrics={metricsInstance}
                  mappingName="Brands"
                  campaignCodeTaggingValues={getBrandTaggingValues}
                >
                  {({ top, footer, main }: Record<string, React.ReactNode>) => (
                    <React.Fragment>
                      <ConductricsInitalizer />
                      <Layout
                        isBrandHomePage={isBrandHomePageUrlFormat(pathname, brandCode)}
                        ohwBaseUrl={env.OHW_BASE_URL ?? ''}
                        appVersion={getAppVersion(env)}
                        top={top}
                        bottom={footer}
                        brandCode={brandCode}
                        localeCode={localeCode}
                        baseAppUrl={baseAppUrl}
                        fullBrandPath={fullBrandPath}
                        brandData={brandData}
                        includeBackToTopButton={includeBackToTopButton}
                        assetPath={getAssetPath(env)}
                        brandSvgPath={getBrandSvg(env, brandCode)}
                        supportedLanguages={
                          'supportedLanguages' in cpmData ? cpmData.supportedLanguages : []
                        }
                        seo={cpmData.mappedPage.seo}
                        articleTitle={cpmData.mappedPage.articleTitle}
                        seoImage={cpmData.mappedPage.seoImage}
                      >
                        {main}
                      </Layout>
                    </React.Fragment>
                  )}
                </CpmRenderer>
              </BrandThemeWrapper>
            </PreviewSegmentsContext.Provider>
          </BrandDataContext.Provider>
        </CorporateAccountContext.Provider>
      </FeatureTogglesContext.Provider>
    </EnvironmentVariablesContext.Provider>
  );
}
