import cx from 'classnames';
import type { IconNames } from '@dx-ui/osc-icon';
import Icon from '@dx-ui/osc-icon';
import { useTranslation } from 'next-i18next';

export type PresetChipTypes = 'featured' | 'new' | 'sale' | 'package';

export type ChipVariants = 'honors' | 'general' | 'marketing' | 'neutral';

export type ChipProps = {
  // The HTML element that the Chip renders as.
  as?: 'div' | 'span';
  className?: string;
  // Whether the Chip inherits colors from the parent component
  inheritStyles?: boolean;
  isIconTrailing?: boolean;
  label?: string;
  preset?: PresetChipTypes;
  variant?: ChipVariants;
} & {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  iconName?: (typeof IconNames)[number] | 'checkmark';
};

const presets: PresetChipTypes[] = ['featured', 'new', 'sale'];

export const presetFilter = (preset: string) => (presets as readonly string[]).includes(preset);

export function Chip({
  as = 'div',
  className,
  iconName,
  inheritStyles = false,
  isIconTrailing = false,
  size = 'md',
  label,
  preset,
  variant,
  ...rest
}: ChipProps) {
  const { t } = useTranslation('osc-chip');
  const chipLabel = preset ? t(preset) : label;
  const VARIANT_STYLES = Object.freeze({
    honors: 'border-tier-diamond text-tier-diamond',
    general: 'border-text text-text',
    marketing: 'border-success text-success',
    neutral: 'border-text-inverse text-text-inverse',
  });
  const PRESET_STYLES = Object.freeze({
    featured: VARIANT_STYLES.marketing,
    new: VARIANT_STYLES.general,
    sale: VARIANT_STYLES.marketing,
    package: VARIANT_STYLES.general,
  });
  const variantClassName = variant && VARIANT_STYLES[variant];
  const presetClassName = preset && PRESET_STYLES[preset];

  const icon = () => {
    if (iconName && iconName !== 'checkmark') {
      return (
        <span className="block" data-testid="osc-chip-icon">
          <Icon name={iconName} size={size === 'xl' ? 'md' : 'xs'} variant="regular" />
        </span>
      );
    }

    if (iconName === 'checkmark') {
      return (
        <span className="block" data-testid="osc-chip-checkmark-icon">
          <Icon name="check" size={size === 'sm' ? 'xs' : 'sm'} />
        </span>
      );
    }

    return null;
  };

  const Element = as;
  return (
    <Element
      data-testid={preset ? `osc-${preset}-chip` : 'osc-chip'}
      {...rest}
      className={cx(
        'inline-flex items-center gap-x-1 rounded-full border font-["Proxima_Nova"] normal-case not-italic',
        {
          'px-2 px-py text-xs font-semibold': size === 'sm',
          'px-3 py-1 text-xs font-semibold': size === 'md',
          'px-3 py-2 text-xs font-bold': size === 'lg',
          'px-3 py-1.5 text-sm font-bold': size === 'xl',
          'border-text text-text': !presetClassName && !variantClassName && !inheritStyles,
          [presetClassName as string]: !!presetClassName && !variantClassName,
        },
        variantClassName,
        className
      )}
    >
      {!isIconTrailing && icon()}
      {chipLabel}
      {isIconTrailing && icon()}
    </Element>
  );
}

export default Chip;
